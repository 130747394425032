export default {
  methods: {
    // Google Analytics + Facebook event tracking
    trackAnalyticsEvent(event, data) {
      window.gtag("event", event, data);
      window.fbq("trackCustom", event, data);
    },

    // Handle when the input changes
    inputChanged(input) {
      input.hasError = false;
    },

    // Form validation
    validateForm(fields) {
      let isValid = true;
      for (const field of Object.keys(fields)) {
        const input = fields[field];
        if (
          input.required &&
          (input.value.length == 0 || input.value == undefined)
        ) {
          isValid = false;
          input.hasError = true;
        }
      }

      return isValid;
    },

    // Create JSON object for data transfer
    parseData(fields) {
      const data = {};
      for (const field of Object.keys(fields)) {
        const input = fields[field];
        if (input.value.length !== 0 || input.value !== undefined) {
          data[field] = input.value;
        }
      }

      return data;
    },

    // Reset form
    resetForm(fields) {
      for (const field of Object.keys(fields)) {
        fields[field].value = "";
        fields[field].hasError = false;
      }
    },
  },
};
