<template>
  <div class="wrapper">
    <div class="brand-wall">
      <div class="brand-group">
        <a
          v-for="brand in brands"
          :key="brand.id"
          :href="brand.url"
          target="_blank"
        >
          <img :src="getLogoURL(brand.logo)" :alt="`${brand.logo} logo`" />
        </a>
        <a
          v-for="brand in brands"
          :key="brand.id"
          :href="brand.url"
          target="_blank"
        >
          <img :src="getLogoURL(brand.logo)" :alt="`${brand.logo} logo`" />
        </a>
      </div>
    </div>
  </div>
</template>
      
<script>
export default {
  name: "BrandWall",
  data() {
    return {
      brands: [
        { logo: "thepurpleturtles", url: "https://thepurpleturtles.com/" },
        { logo: "soulfull", url: "https://www.soulfull.co.in/" },
        { logo: "mokobara", url: "https://mokobara.com/" },
        { logo: "onsra", url: "https://www.onsra.com/" },
        { logo: "corkcicle", url: "https://www.corkcicle.com/" },
        { logo: "subimods", url: "https://subimods.com/" },
        { logo: "ams", url: "https://amsperformance.com/" },
        { logo: "ripple", url: "https://rippleyogawear.com/" },
        { logo: "sangya-project", url: "https://sangyaproject.com/" },
        { logo: "bumsonthesaddle", url: "https://bumsonthesaddle.com/" },
      ],
    };
  },
  methods: {
    getLogoURL(brand) {
      return require(`@/assets/images/logos/${brand}.png`);
    },
  },
};
</script>
      
<style scoped lang="scss">
$size: 2rem;
$gap: 0.5rem;

template {
  display: grid;
  align-content: center;
  overflow: hidden;
  gap: $gap;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: $gap;
  margin: auto;
}
.brand-wall {
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: $gap;
  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );

  img {
    height: 1.75rem;
    margin: 0 2.5rem;
  }
}

.brand-group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: $gap;
  min-width: 100%;
  animation: scroll-x 90s linear infinite;

  &:hover {
    animation-play-state: paused;
  }
}

@keyframes scroll-x {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - $gap));
  }
}

@media screen and (max-width: 800px) {
  .brand-wall {
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
    padding-bottom: 1rem;
    margin-bottom: -1.5rem;

    img {
      display: inline-block;
      vertical-align: middle;
      margin: 0 1.5rem;
    }
  }

  .brand-group {
    animation: scroll-x 30s linear infinite;
  }
}
</style>
      