<template>
  <div class="integrations-wrapper">
    <!--START: AI Features-->
    <AIFeatures></AIFeatures>
    <!--END: AI Features-->

    <!--START: Logos-->
    <div class="integration-logos">
      <h2 class="main-title">Integrate with what you love</h2>
      <div>
        <img
          src="@/assets/images/logos/shopify-wide.png"
          alt="Shopify Integration"
        />
        <img
          src="@/assets/images/logos/zendesk.png"
          alt="Zendesk Integration"
        />
        <img src="@/assets/images/logos/open-ai.png" alt="OpenAi Integration" />
        <img
          src="@/assets/images/logos/gorgias.png"
          alt="Gorgias Integration"
        />
        <img
          src="@/assets/images/logos/aftership.png"
          alt="Aftership Integration"
        />
      </div>
      <span>and more</span>
    </div>
    <!--END: Logos-->

    <div class="onboarding-wrapper">
      <div class="info-title">
        <div class="title-gradient"></div>
        <div
          class="gradient-word gradient-title-header gradient-title-header-light"
        >
          Easiest Go-Live Ever
        </div>
        <h2 class="main-title">
          Fully-assisted and free
          <b>Onboarding & Launch</b>
          with a <i> dedicated success manager</i>
        </h2>
      </div>

      <!--START: E-commerce Features-->
      <div class="ecommerce-features-wrapper">
        <div
          v-for="feature in ecommerceFeatures"
          :key="feature._id"
          class="feature-item"
        >
          <h3>{{ feature.title }}</h3>
          <p>{{ feature.description }}</p>
        </div>
      </div>
      <!--END: E-commerce Features-->
    </div>
  </div>
</template>
          
<script>
// Import components
import AIFeatures from "@/components/Home/AIFeatures";

export default {
  name: "SupportIntegrations",
  data() {
    return {
      ecommerceFeatures: [
        {
          title: "Dedicated Onboarding",
          description:
            "Relax as our team takes care of every detail, from setup to customization. We'll ensure your AI solution is tailored to your needs and ready to go live hassle-free.",
        },
        {
          title: "Dedicated Success Manager",
          description:
            "With Macha, you're not just getting software – you're getting a partner. We'll oversee performance and ensure your AI support evolves seamlessly alongside your business.",
        },
        {
          title: "Zero Resources Needed",
          description:
            "Sit back and let us handle the heavy lifting. No need for additional resources or technical expertise on your end. We'll make your AI support go-live effortless for you.",
        },
      ],
    };
  },
  components: { AIFeatures },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      const sections = document.querySelectorAll(".autoplay-section");

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        const offset = 200;
        const sectionPosY = section.getBoundingClientRect().top - offset;

        if (sectionPosY < 0) {
          this.currentSection = section.id;

          switch (section.id) {
            case "generate-answer":
              if (!this.answerGenerated) {
                this.answerGenerated = true;
                this.generateResponse(0);

                window.removeEventListener("scroll", this.onScroll);
              }
              break;
            default:
              break;
          }
        }
      }
    },

    getFeatureImage(image) {
      return require(`@/assets/images/helpdesk-features/${image}.png`);
    },

    generateResponse(index) {
      if (!this.generatingMessage) {
        this.generatingMessage = true;

        this.responseMessage = "";
        this.selectedResopnse = index;
        this.createResponseMessage(this.responseOptions[index]);
      }
    },

    createResponseMessage(response) {
      if (this.responseMessage.length < response.length) {
        this.responseMessage += response.charAt(this.responseMessage.length);

        setTimeout(
          function () {
            this.createResponseMessage(response);
          }.bind(this),
          0
        );
      } else {
        this.generatingMessage = false;
      }
    },
  },
};
</script>
          
<style scoped lang="scss">
.gradient-word.gradient-title-header {
  position: relative;
  margin: 0 auto 1rem;
  font-size: 1.05rem;

  img {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -3rem;
    width: 2rem;
  }
}

.integrations-wrapper {
  text-align: center;
  padding: 8rem 12.5% 10rem;
  background: $whiteColor;

  .info-title {
    position: relative;

    p {
      position: relative;
      color: $greyColor;
      font-size: $largeFontSize;
      font-weight: $normalFontWeight;
      opacity: $lightOpacity;
      margin: 2rem auto;
      width: 70%;
    }
  }
}

.main-title {
  position: relative;
  color: $whiteColor;
  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(circle at bottom right, #575757, #1d1d1d);
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: $lightFontWeight;
  font-family: "Baskervville";
  font-size: 2.75rem;
  line-height: 1.25;

  b {
    display: block;
    font-size: 4.5rem;
    font-family: $titleFont;
    text-transform: uppercase;
    letter-spacing: -2px;
    margin-bottom: -0.5rem;
  }
}

.ecommerce-features-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;

  .feature-item {
    flex-grow: 1;
    width: calc(33% - 3rem);
    text-align: left;

    &:nth-child(3n - 1) {
      margin-left: 3rem;
      margin-right: 3rem;
    }

    h3 {
      font-size: 1.25rem;
    }
  }
}

.title-gradient {
  background: linear-gradient(
    180deg,
    rgba(196, 224, 254, 0) 0%,
    rgb(219 212 239) 52%,
    rgba(196, 224, 254, 0) 100%
  );
  position: absolute;
  top: -50%;
  left: -20%;
  height: 70vh;
  width: 140%;
  opacity: 0.75;
  filter: blur(10px);
  transform: translate3d(0, 0, 0);
}

.integration-logos {
  position: relative;
  text-align: center;
  margin: 7rem 0 0;

  .main-title {
    margin-bottom: 3rem;
  }

  label {
    font-size: $smallFontSize;
    color: $greyColor;
    opacity: $lightOpacity;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }

  img {
    display: block;
    height: 1.85rem;
    margin: 0 0.5rem;
    padding: 1.25rem 2.75rem;
    border-radius: 1rem;
    background: #f9f9f987;
    border: 1px solid darken(#f5f5f5, 5%);
  }

  span {
    display: block;
    margin-top: 1rem;
    font-size: $mediumFontSize;
    font-weight: $lightFontWeight;
    color: $greyColor;
    opacity: $lightOpacity;
  }
}

.handoff-wrapper {
  text-align: center;
  margin-bottom: 10rem;

  .main-title {
    // text-align: left;
  }
}

.gradient-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 140%;
  min-width: 140%;
  filter: blur(20px);
  transform: translate3d(0, 0, 0);

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  object-fit: cover;
  animation: rotateGradient 10s infinite;
  animation-play-state: paused;
  animation-timing-function: ease-in;
  transform-origin: bottom;
}

.features-list {
  margin: 4rem 0 5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5rem;
}

.feature-item {
  width: 50%;
  // text-align: left;

  &:last-child {
    .gradient-bg {
      transform: translate(-50%, -50%) rotate(-50deg);
      animation: none;
    }
  }

  .image-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 1.5rem;
    isolation: isolate;
    border: 1px solid #e7e7e7;

    &:hover {
      .gradient-bg {
        animation-play-state: running;
      }
    }

    .feature-img {
      position: relative;
      display: block;
      width: 100%;
    }
  }

  h3 {
    color: $darkBlackColor;
    font-size: $largestFontSize;
    margin: 1.75rem 0 1rem;
  }

  p {
    font-size: 1rem;
    color: $greyColor;
    font-weight: $normalFontWeight;
    opacity: $lightOpacity;
  }
}

@keyframes rotateGradient {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  50% {
    transform: translate(-50%, -50%) rotate(-50deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

.integration-features {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 2.5%;

  .info-title {
    text-align: left;
    width: 50%;
    margin-right: 5rem;
  }

  .logos-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;

    img {
      height: 1.25rem;
      padding: 0.75rem 2rem;
      border-radius: 2rem;
      border: 1px solid darken(#f9f9f9, 10%);
      background-color: #f9f9f9;
      margin-right: 1rem;
    }
  }
}

.usp-list {
  text-align: left;
  width: calc(50% - 5rem);
}

.usp-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    color: $darkBlackColor;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    color: $greyColor;
    font-weight: $normalFontWeight;
    opacity: $lightOpacity;
  }

  .details {
    flex: 1;
  }

  .icon {
    position: relative;
    background: $emeraldColor;
    height: 13.5px;
    width: 13.5px;
    box-shadow: 0 0 0.7rem 0.05rem #8fedbc;
    border-radius: 50%;
    border: 2px solid #acf0cb;
    margin-top: 0.5rem;
    margin-right: 2.25rem;
  }
}

.onboarding-wrapper {
  margin-top: 11rem;
}

@media screen and (max-width: 800px) {
  .onboarding-wrapper {
    margin-top: 5rem;
  }

  .gradient-word.gradient-title-header {
    font-size: $smallFontSize;
    margin: 0 0 1rem 0;

    img {
      width: 1.75rem;
      left: -2.5rem;
    }
  }

  .ecommerce-features-wrapper {
    display: block;
    white-space: nowrap;
    overflow-x: scroll;
    margin-left: -1.5rem;
    padding-left: 1.5rem;
    width: calc(100% + 1.5rem);
    margin-top: 1.5rem;

    .feature-item {
      vertical-align: top;
      display: inline-block;
      width: 70%;
      white-space: normal;
      margin-bottom: 1rem;
      padding: 1rem;
      margin-right: 1.25rem;
      min-height: 20vw;
      border-radius: 1rem;
      background: #faf9ff;
      border: 1px solid #ebe6ff;

      &:nth-child(3n - 1) {
        margin-left: 0;
        margin-right: 1.5rem;
      }

      h3 {
        font-size: $normalFontSize;
        margin-bottom: 0.5rem;
        margin-top: 0;
      }

      p {
        font-size: $smallFontSize;
      }
    }
  }

  .integrations-wrapper {
    padding: 6rem 1.5rem;

    .info-title {
      text-align: left;

      p {
        width: auto;
      }
    }
  }

  .main-title {
    position: relative;
    font-size: 1.5rem;
    line-height: 1.1;
    z-index: 2;

    b {
      font-size: 2rem;
      margin-top: 0.25rem;
      margin-bottom: 0;
    }
  }

  .reply-demo-wrapper {
    flex-direction: column;
    margin-bottom: 7rem;

    .reply-demo-block {
      width: 100%;
      margin-right: 0;

      .user-message {
        width: calc(100% - 4rem - 1.5rem - 3rem) !important;
      }
    }

    .reply-demo-info {
      width: auto;
      margin-top: 3rem;
    }

    .response-actions-wrapper {
      overflow-x: scroll;
      white-space: nowrap;
    }
  }

  .integration-logos {
    margin-top: 6rem;

    .main-title {
      margin-bottom: 2rem;
    }

    div {
      display: block;
    }

    img {
      display: inline-block;
      height: auto;
      width: 5rem;
      padding: 1rem 2rem;
      margin-bottom: 0.75rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    span {
      font-size: $smallFontSize;
    }
  }

  .features-list {
    display: block;
    margin-top: 3rem;
  }

  .feature-item {
    width: auto;
    text-align: center;
    margin-bottom: 2.5rem;
  }

  .integration-features {
    display: block;
    margin: 0;

    .info-title {
      width: auto;
      margin-right: 0;
    }

    .usp-list {
      width: auto;
      margin-top: 3rem;

      .usp-item {
        .icon {
          margin-right: 1rem;
        }
      }
    }
  }
}
</style>
          