<template>
  <div class="hero-wrapper">
    <!--START: Info Wrapper-->
    <div class="info-wrapper">
      <div class="badge">
        <img src="@/assets/images/logos/open-ai-mark.png" alt="OpenAI logo" />
        <span>Powered by GPT-4 from OpenAI</span>
      </div>
      <h1 class="title">
        Deliver <i>delightful customer experiences</i> with<br />
        <span class="gradient-word">AI-Powered Support</span><b>✨</b>
      </h1>
      <HeroDescription></HeroDescription>
    </div>
    <!--END: Info Wrapper-->

    <!--START: Line Animation-->
    <LineAnimation></LineAnimation>
    <LineAnimation class="line-right"></LineAnimation>
    <!--END: Line Animation-->

    <!--START: Gradient Background-->
    <GradientBackground></GradientBackground>
    <!--END: Gradient Background-->

    <!--START: Brands Wraper-->
    <div class="brands-wrapper">
      <span
        >Trusted by brands to elevate their customer
        support</span
      >

      <BrandWall class="logos"></BrandWall>
    </div>
    <!--END: Brands Wraper-->
  </div>
</template>
    
<script>
//Import components
import GradientBackground from "@/components/GradientBackground";
import LineAnimation from "./LineAnimation";
import HeroDescription from "./HeroDescription";
import BrandWall from "@/components/Home/BrandWall";

export default {
  name: "HeroHome",
  components: {
    GradientBackground,
    LineAnimation,
    HeroDescription,
    BrandWall,
  },
  methods: {},
};
</script>
    
<style scoped lang="scss">
.hero-wrapper {
  position: relative;
  overflow: hidden;
  padding: 8.25rem 10% 8rem;

  &::before {
    content: "";
    position: absolute;
    background-image: url(@/assets/images/noise.webp);
    background-position: 0 0;
    background-repeat: repeat;
    background-size: auto;
    background-attachment: fixed;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.85;
  }

  &:hover {
    .gradient-color {
      animation-play-state: running;
    }
  }

  .chat-container {
    position: relative;
    flex: 1;
    width: 25rem;
    margin-right: 5rem;
  }

  .info-wrapper {
    position: relative;
    margin: 1rem auto 0;
    text-align: center;
  }
}

.title {
  color: #e8eaed;
  font-weight: 300;
  letter-spacing: -0.05rem;
  line-height: 1;
  margin: 1.5rem 0 1rem;
  font-family: "Baskervville";
  font-size: 2.75rem;

  .gradient-word {
    display: inline-block;
    font-size: 6rem;
    margin-top: 1rem;
  }

  b {
    display: inline-block;
    margin-right: -2.5rem;
    margin-left: 0.5rem;
    font-size: 3rem;
    transform: translateY(-3rem);
  }
}

.badge {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding: 0.5rem 1.25rem;
  border-radius: 1.5rem;
  border: 1px solid #66488c;
  margin: auto;
  background: #4d3568;

  img {
    display: inline-block;
    width: 1rem;
    margin-right: 0.5rem;
  }

  span {
    color: #e8ddf6;
    font-size: $smallFontSize;
  }
}

.brands-wrapper {
  position: relative;
  margin-top: 6rem;
  z-index: 5;

  span {
    display: none;
    font-size: $smallerFontSize;
    text-align: center;
    color: $whiteColor;
    opacity: $lightOpacity;
    margin-bottom: 1.5rem;
  }
}

.mobile {
  display: none !important;
}

@media screen and (max-width: 800px) {
  .desktop {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
  .hero-wrapper {
    display: block;
    padding: 7rem 1.5rem;

    .chat-container {
      width: 100%;
      margin-right: 0;
    }
    .info-wrapper {
      width: 100%;
      text-align: center;

      .badge {
        margin: auto;
      }
    }
  }

  .title {
    position: relative;
    font-size: 2rem;
    margin-bottom: 0;
    line-height: 1.2;

    .gradient-word {
      font-size: 3.5rem;
      margin-top: 0.5rem;
      letter-spacing: -3px;
      line-height: 1;
    }

    b {
      position: absolute;
      margin-right: 0;
      margin-left: 0;
      transform: none;
      right: 9vw;
      bottom: 2rem;
      font-size: 1.75rem;
    }
  }

  .line-container {
    display: none;
  }

  .brands-wrapper {
    margin-top: 5rem;
    overflow: hidden;
    padding-bottom: 1rem;
  }

  .badge {
    span {
      font-size: $smallerFontSize;
    }
  }
}
</style>
    