import { createWebHistory, createRouter } from "vue-router";

import Home from "@/views/Home.vue";
import Pricing from "@/views/Pricing.vue";
import Contact from "@/views/Contact.vue";
import CreateAccount from "@/views/CreateAccount.vue";
import Success from "@/views/Success.vue";

import Zendesk from "@/views/Zendesk/Index.vue";
import ZendeskPricing from "@/views/Zendesk/Pricing.vue";

import Policies from "@/views/Policies/Index.vue";
import PrivacyPolicy from "@/views/Policies/PrivacyPolicy.vue";
import RefundPolicy from "@/views/Policies/RefundPolicy.vue";
import TermsOfUse from "@/views/Policies/TermsOfUse.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: Pricing,
  },
  {
    path: "/account/create",
    name: "CreateAccount",
    component: CreateAccount,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/success",
    name: "Success",
    component: Success,
  },
  {
    path: "/zendesk",
    name: "Zendesk",
    component: Zendesk,
  },
  {
    path: "/zendesk/pricing",
    name: "ZendeskPricing",
    component: ZendeskPricing,
  },
  {
    path: "/policies",
    name: "Policies",
    component: Policies,
    children: [
      {
        path: "privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
      },
      {
        path: "refund-policy",
        name: "RefundPolicy",
        component: RefundPolicy,
      },
      {
        path: "terms-of-use",
        name: "TermsOfUse",
        component: TermsOfUse,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      console.log(to);
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
    return { top: 0 };
  },
});

export default router;
