<template>
  <div class="demo-wrapper">
    <!--START: Header and Gradient-->
    <Header :theme="headerTheme"></Header>
    <!--END: Header and Gradient-->

    <div id="contact-wrapper" class="demo-form-wrapper scroll-section">
      <div class="contact-gradient-circle"></div>

      <!--START: Info Wrapper-->
      <div class="info-wrapper">
        <h1 class="main-title"><b>Say hello.</b>We're <i>happy to help</i></h1>
        <div class="inclusions-list desktop">
          <label>Learn how Macha can reduce ticket volumes by 50%</label>
          <div v-for="item in inclusions" :key="item.id" class="inclusion">
            <unicon name="check"></unicon>
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
      <!--END: Info Wrapper-->

      <!--START: Contact Form-->
      <ContactForm></ContactForm>
      <!--END: Contact Form-->

      <!--START: Mobile Inclusions-->
      <div class="inclusions-list mobile">
        <label>Along with all key features, you also get the following</label>
        <div v-for="item in inclusions" :key="item.id" class="inclusion">
          <unicon name="check"></unicon>
          <span>{{ item }}</span>
        </div>
      </div>
      <!--END: Mobile Inclusions-->
    </div>

    <!--START: Brands-->
    <div class="brands-wrapper">
      <BrandWall class="logos"></BrandWall>
      <span>Trusted by brands to provie amazing AI support</span>
    </div>
    <!--END: Brands-->

    <!--START: Customer Story-->
    <CustomerStory></CustomerStory>
    <!--END: Customer Story-->

    <div id="statistics" class="white-background scroll-section">
      <!--START: Statistics-->
      <SupportStatistics></SupportStatistics>
      <!--END: Statistics-->

      <div class="gradient-circle"></div>
    </div>

    <!--START: Header and Gradient-->
    <Footer></Footer>
    <!--END: Header and Gradient-->
  </div>
</template>
      
<script>
//Import components
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import ContactForm from "@/components/Forms/ContactForm";
import CustomerStory from "@/components/Home/CustomerStory";
import BrandWall from "@/components/Home/BrandWall";
import SupportStatistics from "@/components/Home/SupportStatistics";

export default {
  name: "CreateDemo",
  data() {
    return {
      headerTheme: "light",
      inclusions: [
        "AI made for Shopify",
        "Deep integration with Shopify",
        "Works with Gorgias and Zendesk",
        "No more 'where is my order?' queries",
        "Beta pricing for our early-access customers",
      ],
    };
  },
  components: {
    ContactForm,
    CustomerStory,
    BrandWall,
    SupportStatistics,
    Header,
    Footer,
  },
  computed: {},
  async created() {
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    // Header menu selection
    onScroll() {
      const scrollY = window.scrollY + 75;
      const sections = document.querySelectorAll(".scroll-section");

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];

        if (
          section.offsetTop <= scrollY &&
          section.offsetTop + section.offsetHeight >= scrollY
        ) {
          // Header theme styles
          switch (section.id) {
            case "statistics":
              this.headerTheme = "dark";
              break;
            default:
              this.headerTheme = "light";
              break;
          }
        }
      }
    },
  },
};
</script>
      
<style scoped lang="scss">
.white-background,
.demo-wrapper {
  position: relative;
  background-color: $whiteColor;
}

.main-title {
  position: relative;
  color: $whiteColor;
  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(circle at bottom right, #575757, #1d1d1d);
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: $lightFontWeight;
  font-family: "Baskervville";
  font-size: 2.5rem;
  line-height: 1.25;

  :deep(b) {
    display: block;
    font-size: 4.5rem;
    font-family: $titleFont;
    text-transform: uppercase;
    letter-spacing: -2px;
    margin-bottom: -0.5rem;
  }
}

.gradient-circle {
  position: absolute;
  background-color: #ffe7b5;
  top: -35%;
  right: -45%;
  border-radius: 50%;
  height: 55%;
  width: 85%;
  filter: blur(100px);
  transform: translate3d(0, 0, 0);

  opacity: 0.5;
}

.contact-gradient-circle {
  position: absolute;
  background-color: #e1d5ff;
  bottom: -15%;
  right: -25%;
  border-radius: 50%;
  height: 55%;
  width: 55%;
  filter: blur(100px);
  transform: translate3d(0, 0, 0);

  opacity: 0.5;
}

.demo-form-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10rem 15% 0;

  .info-wrapper {
    width: 50%;
    margin-right: 5rem;
  }

  p {
    font-size: $mediumFontSize;
  }

  .form-wrapper {
    flex: 1;
  }
}

.inclusions-list {
  margin-top: 2rem;

  label {
    display: block;
    font-weight: $mediumFontWeight;
    font-size: $normalFontSize;
    margin-bottom: 2.5rem;
  }
  .inclusion {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
  }

  :deep(svg) {
    fill: darken($emeraldColor, 30%);
    width: auto;
    height: 1rem;
    margin-right: 1rem;
    background: lighten($emeraldColor, 25%);
    border-radius: 50%;
    padding: 2px;
  }
}

.brands-wrapper {
  position: relative;
  z-index: 5;
  margin: 8rem auto;

  span {
    font-size: $smallerFontSize;
    display: block;
    text-align: center;
    color: $blackColor;
    opacity: $lightOpacity;
    margin-top: 2rem;
  }

  .logos {
    :deep(img) {
      filter: invert(1);
    }
  }
}

.testimonial-wrapper {
  margin-top: 4rem;
}
.mobile {
  display: none;
}
@media screen and (max-width: 800px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }

  .demo-form-wrapper {
    display: block;
    padding: 7rem 1.5rem 1rem;

    .info-wrapper {
      width: auto;
      margin: 0;
    }
  }

  .main-title {
    font-size: 2.25rem;
    margin-bottom: 2rem;
    text-align: center;

    :deep(b) {
      font-size: 3.5rem;
    }
  }

  .inclusions-list {
    label {
      margin-bottom: 1.5rem;
    }
  }

  .inclusions-list {
    margin-top: 3rem;
  }

  :deep(.brands-wrapper) {
    margin-top: 5rem;
  }
}
</style>