import axios from "axios";
const apiURL = `https://www.getmacha.com/controllers`;

class CustomerService {
   // Save customer
   static SaveCustomer(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/save-customer.php`, data)
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

}

export default CustomerService;
