<template>
  <div>
    <!--START: Header and Gradient-->
    <Header :currentSection="currentSection" :theme="headerTheme"></Header>
    <!--END: Header and Gradient-->

    <!--START: Hero-->
    <Hero id="hero" class="scroll-section"></Hero>
    <!--END: Hero-->

    <!--START: Shopify-->
    <div id="shopify" class="dark-section scroll-section">
      <div class="dark-section-block">
        <SSPFeatures></SSPFeatures>
      </div>
      <img
        class="gradient-bg"
        src="@/assets/images/gradient.jpg"
        alt="Gradient BG"
      />
    </div>
    <!--END: Shopify-->

    <div id="integrations" class="white-background scroll-section">
      <div>
        <!--START: Integrations-->
        <SupportIntegrations></SupportIntegrations>
        <!--END: Integrations-->

        <!--START: Customer Story-->
        <CustomerStory></CustomerStory>
        <!--END: Customer Story-->
      </div>
    </div>

    <div id="statistics" class="white-background scroll-section">
      <!--START: Statistics-->
      <SupportStatistics></SupportStatistics>
      <!--END: Statistics-->

      <div class="gradient-circle"></div>
    </div>

    <!--START: Header and Gradient-->
    <Footer></Footer>
    <!--END: Header and Gradient-->
  </div>
</template>
    
<script>
// Import components
import Header from "@/components/Header";
import Footer from "@/components/Footer";

import Hero from "@/components/Home/Hero";
import SSPFeatures from "@/components/Home/SSPFeatures";
import SupportIntegrations from "@/components/Home/SupportIntegrations";
import SupportStatistics from "@/components/Home/SupportStatistics";
import CustomerStory from "@/components/Home/CustomerStory";

export default {
  name: "HomePage",
  data() {
    return {
      currentSection: "",
      headerTheme: "",
    };
  },

  components: {
    Header,
    Footer,
    Hero,
    SSPFeatures,
    SupportIntegrations,
    SupportStatistics,
    CustomerStory,
  },
  async created() {
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    // Header menu selection
    onScroll() {
      const scrollY = window.scrollY + 75;
      const sections = document.querySelectorAll(".scroll-section");

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];

        if (
          section.offsetTop <= scrollY &&
          section.offsetTop + section.offsetHeight >= scrollY
        ) {
          this.currentSection = section.id;

          // Header theme styles
          switch (section.id) {
            case "integrations":
              this.headerTheme = "light";
              break;
            default:
              this.headerTheme = "dark";
              break;
          }
        }
      }
    },
  },
};
</script>
    
<style scoped lang="scss">
.dark-section {
  position: relative;
  margin-top: -4rem;
  z-index: 1;

  .dark-section-block {
    position: relative;
    z-index: 1;
    background-color: #111;
    border-top: 1px solid lighten(#111, 15%);
    border-top-left-radius: 4rem;
    border-top-right-radius: 4rem;
    overflow: hidden;
    padding: 5rem 12.5%;

    &::before {
      content: "";
      position: absolute;
      background-image: url(@/assets/images/noise.webp);
      background-position: 0 0;
      background-repeat: repeat;
      background-size: auto;
      background-attachment: fixed;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }

  .gradient-bg {
    position: absolute;
    z-index: -1;
    left: 0;
    width: 100%;
    height: calc(100% - 4rem);
    bottom: -4px;
  }
}

.white-background {
  position: relative;
  background-color: $whiteColor;

  .gradient-circle {
    position: absolute;
    background-color: #ffe7b5;
    top: -35%;
    right: -45%;
    border-radius: 50%;
    height: 55%;
    width: 85%;
    filter: blur(100px);
    transform: translate3d(0, 0, 0);

    opacity: 0.5;
  }
}

@media screen and (max-width: 800px) {
  .shopify-wrapper {
    .shopify-main-block {
      padding: 5rem 1.5rem;
    }
  }

  .dark-section {
    .dark-section-block {
      padding: 5rem 1.5rem 0;

      p {
        width: auto;
      }
    }
  }

  .brands-wrapper {
    position: relative;
    z-index: 2;
  }
}
</style>