<template>
  <div>
    <!--START: Header and Gradient-->
    <Header
      currentSection="pricing"
      :theme="headerTheme"
      @createAccount="createAccount"
    ></Header>
    <!--END: Header and Gradient-->

    <div id="hero" class="white-background scroll-section">
      <!--START: Hero-->
      <div class="info-title">
        <h2 class="main-title">
          <img
            src="@/assets/images/gpt-badge.png"
            class="gpt-badge"
            alt="GPT Powered"
          />
          <i>Customer-centric</i> AI support<br /><b>Choose Your Plan</b>
        </h2>
      </div>
      <!--END: Hero-->

      <!--START: Pricing Tables-->
      <PricingTable selectedOption="helpdesk"></PricingTable>
      <!--END: Pricing Tables-->

      <!--START: Success Manager-->
      <SuccessManager></SuccessManager>
      <!--END: Success Manager-->

      <!--START: Success Manager-->
      <FAQs :faqs="faqs"></FAQs>
      <!--END: Success Manager-->

      <!--START: Customer Story-->
      <CustomerStory></CustomerStory>
      <!--END: Customer Story-->
    </div>

    <!--START: Header and Gradient-->
    <Footer></Footer>
    <!--END: Header and Gradient-->
  </div>
</template>
      
<script>
//Import components
import Header from "@/components/Integrations/Header";
import Footer from "@/components/Footer";

import PricingTable from "@/components/Pricing/PricingTable";
import SuccessManager from "@/components/Pricing/SuccessManager";
import CustomerStory from "@/components/Home/CustomerStory";
import FAQs from "@/components/FAQs";

export default {
  name: "PricingPage",
  data() {
    return {
      headerTheme: "light",
      faqs: [
        {
          question: "Do you have a free trial?",
          answer:
            "Yes, absolutely! You get  <b>50 free answers</b> for the Macha Zendesk widget. No credit card required.",
        },
        {
          question: "Can I integrate my Shopify store?",
          answer:
            "Yes! You can integrate you store to allow your agents create answers about your products, and customer orders.",
        },
        {
          question: "What do Macha AI products do?",
          answer:
            "It helps your support agents provide better and faster responses to tickets with the help of AI powered tools.",
        },
        {
          question: "How long does onboarding take?",
          answer:
            "We provide a white glove onboarding experience. Which means we <b>support you throughout the on-boarding process</b>. You should be up and running in less than 15 mins. As simple as authenticating Zendesk integration.",
        },
        {
          question: "What if I'm not happy with the product?",
          answer:
            "We have a <b>30 day money back guarantee</b> - no questions asked.",
        },
        {
          question: "Do I need to pay for a demo?",
          answer:
            "<b>Absolutely not</b>. You only pay once you are happy to subscribe to the product.",
        },
      ],
      demoTitle: "<b>Free demo</b> for <i>your brand & website</i>",

      inclusions: [
        "Your data. Your policies. Your brand",
        "Live demo link to try for your brand",
        "Walkthrough with our co-founder",
        "No credit card required",
      ],
    };
  },

  components: {
    Header,
    Footer,
    PricingTable,
    SuccessManager,
    CustomerStory,
    FAQs,
  },
  async created() {
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    createAccount(data) {
      this.trackAnalyticsEvent(data.event, data.source);

      this.$store.commit("updateDemoData", {
        source: data.source.source,
        contactType: "create_account",
      });

      window.open(
        "https://www.zendesk.com/in/marketplace/apps/support/986669/macha-ai-support/",
        "_blank"
      );
    },

    // Header menu selection
    onScroll() {
      const scrollY = window.scrollY + 75;
      const sections = document.querySelectorAll(".scroll-section");

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];

        if (
          section.offsetTop <= scrollY &&
          section.offsetTop + section.offsetHeight >= scrollY
        ) {
          // Header theme styles
          switch (section.id) {
            case "hero":
              this.headerTheme = "light";
              break;
            default:
              this.headerTheme = "dark";
              break;
          }
        }
      }
    },
  },
};
</script>
      
<style scoped lang="scss">
.white-background {
  position: relative;
  background-color: $whiteColor;
}

.main-title {
  position: relative;
  color: $whiteColor;
  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(circle at bottom right, #575757, #1d1d1d);
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: $lightFontWeight;
  font-family: "Baskervville";
  font-size: 2.5rem;
  line-height: 1.25;

  :deep(b) {
    display: block;
    font-size: 4.5rem;
    font-family: $titleFont;
    text-transform: uppercase;
    letter-spacing: -2px;
    margin-bottom: -0.5rem;
  }
}

.info-title {
  position: relative;
  padding: 9rem 12.5% 0;
  text-align: center;
  z-index: 2;

  .main-title {
    display: table;
    margin: auto;
    .gpt-badge {
      position: absolute;
      bottom: 1.25rem;
      left: calc(100% - 1.5rem);
      width: 6rem;
      transform: rotate(15deg);
    }
  }

  p {
    position: relative;
    color: $greyColor;
    font-size: $largeFontSize;
    font-weight: $normalFontWeight;
    margin: 1rem auto;
    width: 70%;

    span {
      font-weight: $boldFontWeight;
    }
  }
}

.beta-badge {
  display: table;
  margin: 0 auto 1rem;
  color: #2a9e61;
  background-color: #edfff5;
  border: 1px solid #46cb85;
  padding: 0.55rem 1.5rem;
  border-radius: 1rem;
  font-size: $smallFontSize;
  font-weight: $boldFontWeight;
}

.gradient-circle {
  position: absolute;
  background-color: #ffe7b5;
  top: -35%;
  right: -45%;
  border-radius: 50%;
  height: 55%;
  width: 85%;
  filter: blur(100px);
  transform: translate3d(0, 0, 0);

  opacity: 0.5;
}

.demo-form-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6rem 15% 0;

  .info-wrapper {
    width: 50%;
    margin-right: 5rem;
  }

  .form-wrapper {
    flex: 1;
  }

  .gradient-circle {
    position: absolute;
    background-color: rgb(167, 138, 250);
    right: -45%;
    border-radius: 50%;
    top: calc(50% + 6rem);
    transform: translateY(-50%);
    height: 55%;
    width: 90%;
    filter: blur(100px);
    transform: translate3d(0, 0, 0);

    opacity: 0.5;
  }
}

.inclusions-list {
  label {
    display: block;
    font-weight: $mediumFontWeight;
    font-size: $normalFontSize;
    margin: 2.5rem 0 2rem;
  }
  .inclusion {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
  }

  :deep(svg) {
    fill: darken($emeraldColor, 30%);
    width: auto;
    height: 1rem;
    margin-right: 1rem;
    background: lighten($emeraldColor, 25%);
    border-radius: 50%;
    padding: 2px;
  }
}

:deep(footer) {
  padding-top: 5rem;
}

:deep(.testimonial-wrapper) {
  position: relative;
  margin-bottom: 0;

  &::before {
    content: "";
    position: absolute;
    display: block;
    top: 60%;
    width: 100%;
    height: 41%;
    background: #0c0c0c;
  }
}

.mobile {
  display: none;
}

@media screen and (max-width: 800px) {
  .main-title {
    position: relative;
    font-size: 1.5rem;
    line-height: 1.1;
    z-index: 2;

    b {
      font-size: 2.5rem;
      margin-top: 0.25rem;
      margin-bottom: 0;
    }
  }

  .info-title {
    padding: 8rem 1.5rem 0;
    .main-title {
      .gpt-badge {
        width: 3rem;
        left: calc(100% - 0.5rem);
      }
    }

    p {
      width: auto;
    }
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }

  .demo-form-wrapper {
    display: block;
    padding: 5rem 1.5rem 1rem;

    .info-wrapper {
      width: auto;
      margin: 0;
    }

    .main-title {
      position: relative;
      font-size: 1.5rem;
      line-height: 1.1;
      text-align: center;
      z-index: 2;
      margin-bottom: 1rem;

      :deep(b) {
        font-size: 2rem;
        margin-top: 0.25rem;
        margin-bottom: 0;
      }
    }
    .inclusions-list {
      margin-top: 3rem;
      label {
        margin-bottom: 1.5rem;
      }
    }
  }
}
</style>