<template>
  <div class="integrations-wrapper">
    <!--START: Widget Features-->
    <div class="widget-list">
      <!--START: Title-->
      <div class="info-title">
        <div class="title-gradient"></div>
        <div
          class="gradient-word gradient-title-header gradient-title-header-light"
        >
          Macha AI widget for Zendesk
        </div>
        <h2 class="main-title">An AI assistant your <b>agents will love</b></h2>
      </div>
      <!--END: Title-->
      <div class="widget-features-wrapper">
        <div
          v-for="feature in widgetFeatures"
          :key="feature._id"
          class="feature-item"
        >
          <h3>{{ feature.title }}</h3>
          <p>{{ feature.description }}</p>
        </div>
      </div>
    </div>
    <!--END: Widget Features-->

    <!--START: Feature-->
    <div class="widget-features">
      <div class="widget-feature-item">
        <div class="info-wrapper">
          <div class="info-title">
            <h2 class="main-title">
              Simple instructions.
              <b>Incredible replies.</b>
            </h2>
          </div>
          <p>
            Just give a short instruction to the AI, and it'll draft out a
            complete response for you. The draft will include greetings,
            detailed response as per instruction and closing salutation. Never
            waste time on drafting a response again.
          </p>
        </div>
        <div class="feature-img-wrapper">
          <img
            class="gradient-bg"
            src="@/assets/images/gradient-light.jpg"
            alt="Gradient BG"
          />
          <img
            class="feature-img"
            src="@/assets/images/zendesk/simple-instruction.gif"
            alt="Zendesk
         Demo"
          />
        </div>
      </div>
      <div class="widget-feature-item flip">
        <div class="info-wrapper">
          <div class="info-title">
            <h2 class="main-title">
              Replies that reflects
              <b>Brand Persona</b>
            </h2>
          </div>
          <p>
            You can define the AI's persona and tone that best suits your brand.
            Whether it's young and casual or professional and formal, the AI
            will generate your reply however you like.
          </p>
        </div>
        <div class="feature-img-wrapper feature-img-alt">
          <img
            class="gradient-bg"
            src="@/assets/images/gradient-light.jpg"
            alt="Gradient BG"
          />
          <img
            class="feature-img"
            src="@/assets/images/zendesk/persona.png"
            alt="Zendesk
         Demo"
          />
        </div>
      </div>
      <div class="widget-feature-item">
        <div class="info-wrapper">
          <div class="info-title">
            <h2 class="main-title">
              Knowledge base to
              <b>enrich replies</b>
            </h2>
          </div>
          <p>
            The AI uses your Zendesk Help Centre articles and macros while
            generating responses. Want it to summarise a policy or provide a
            link from your articles? Just instruct the AI with a short phrase.
          </p>
        </div>
        <div class="feature-img-wrapper">
          <img
            class="gradient-bg"
            src="@/assets/images/gradient-light.jpg"
            alt="Gradient BG"
          />
          <img
            class="feature-img"
            src="@/assets/images/zendesk/kb-assist.gif"
            alt="Zendesk
         Demo"
          />
        </div>
      </div>
      <div class="widget-feature-item flip">
        <div class="info-wrapper">
          <div class="info-title">
            <h2 class="main-title">
              Reply in over
              <b>80+ lanuages</b>
            </h2>
          </div>
          <p>
            The AI can generate a response in 80+ languages. Instruct in one
            language and generate a response in another. Your agents can now
            communicate in over 80 languages.
          </p>
        </div>
        <div class="feature-img-wrapper feature-img-alt">
          <img
            class="gradient-bg"
            src="@/assets/images/gradient-light.jpg"
            alt="Gradient BG"
          />
          <img
            class="feature-img"
            src="@/assets/images/zendesk/translation.png"
            alt="Zendesk
         Demo"
          />
        </div>
      </div>
    </div>
    <!--END: Feature-->

    <div class="cta-wrapper integrations-wrapper">
      <div class="info-title">
        <h2 class="main-title">
          Use AI to generate replies in your brand tone, with language
          translations
        </h2>
      </div>
      <button class="btn btn-primary" @click="createAccount">
        Try Zendesk Widget for Free
      </button>
    </div>

    <!--START: Integration USPs-->
    <div class="integration-features">
      <div class="info-title">
        <div class="logos-wrapper">
          <img
            src="@/assets/images/logos/zendesk.png"
            alt="Zendesk Integration"
          />
        </div>
        <h2 class="main-title">
          Advantage of using <b>Macha AI for Zendesk</b>
        </h2>
      </div>

      <div class="usp-list">
        <div
          class="usp-item"
          v-for="feature in integrationFeatures"
          :key="feature._id"
        >
          <div class="icon"></div>
          <div class="details">
            <h3>{{ feature.title }}</h3>
            <p>{{ feature.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <!--END: Integration USPs-->
  </div>
</template>
            
  <script>
export default {
  name: "ZendeskFeatures",
  data() {
    return {
      widgetFeatures: [
        {
          title: "Simple instructions",
          description:
            "AI drafts responses for your agents, using short instructions, help center articles, macros and other sources",
        },
        {
          title: "Brand tone and voice",
          description:
            "Create AI brand personas to add character, empathy and even emojis to create amazing replies",
        },
        {
          title: "Reply in any language",
          description: "Effortlessly generate a response in 80+ languages",
        },
        {
          title: "Actionable insights and analysis",
          description:
            "AI creates summaries with key highlights and action points, for quick analysis",
        },
        {
          title: "Recommends macros and articles",
          description:
            "Get relevant macros and help center article suggestions",
        },
        {
          title: "Native Shopify integration",
          description:
            "Shopify store? The AI will use your products and customer orders while generating replies",
        },
      ],
      integrationFeatures: [
        {
          title: "Easy On-boarding",
          description: "You can start using our app in just 3 simple steps ",
        },
        {
          title: "Usage based Pricing. Not per agent.",
          description:
            "Pay for only the answers you generate. Regardless of how many agents.",
        },
        {
          title: "No OpenAI Account Required",
          description:
            "You don't need your own OpenAI account. We'll take care of it",
        },
      ],
    };
  },
  created() {},
  methods: {
    getFeatureImage(image) {
      return require(`@/assets/images/helpdesk-features/${image}.png`);
    },

    createAccount() {
      const data = {
        event: "create_account",
        source: { source: "zendesk_features" },
      };
      this.$emit("createAccount", data);
    },
  },
};
</script>
            
<style scoped lang="scss">
.integrations-wrapper {
  text-align: center;
  padding: 6rem 12.5%;
  background: $whiteColor;

  .info-title {
    position: relative;
    .main-title {
      position: relative;
      color: $whiteColor;
      -webkit-text-fill-color: transparent;
      background-image: radial-gradient(
        circle at bottom right,
        #575757,
        #1d1d1d
      );
      -webkit-background-clip: text;
      background-clip: text;
      font-weight: $lightFontWeight;
      font-family: "Baskervville";
      font-size: 2.75rem;
      line-height: 1.25;

      b {
        display: block;
        font-size: 4.5rem;
        font-family: $titleFont;
        text-transform: uppercase;
        letter-spacing: -2px;
        margin-bottom: -0.5rem;
        line-height: 1.1;
      }
    }

    p {
      position: relative;
      color: $greyColor;
      font-size: $largeFontSize;
      font-weight: $normalFontWeight;
      opacity: $lightOpacity;
      margin: 2rem auto;
      width: 70%;
    }
  }
}

.title-gradient {
  background: linear-gradient(
    180deg,
    rgba(196, 224, 254, 0) 0%,
    rgb(219 212 239) 52%,
    rgba(196, 224, 254, 0) 100%
  );
  position: absolute;
  top: 7.5vh;
  left: -20%;
  height: 70vh;
  width: 140%;
  opacity: 0.75;
  filter: blur(10px);
  transform: translate3d(0, 0, 0);
}

.widget-list {
  margin: 5rem 0 10rem;
}

.widget-features-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  gap: 3rem;

  .feature-item {
    flex-grow: 1;
    width: calc(33% - 3rem);
    text-align: left;
    margin-bottom: 0rem;

    h3 {
      font-size: 1.25rem;
    }
  }
}

.handoff-wrapper {
  text-align: center;
  margin-bottom: 12rem;
}

.gradient-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 140%;
  min-width: 140%;
  filter: blur(20px);
  transform: translate3d(0, 0, 0);

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  object-fit: cover;
  animation: rotateGradient 10s infinite;
  animation-play-state: paused;
  animation-timing-function: ease-in;
  transform-origin: bottom;
}

.features-list {
  margin: 4rem 0 5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 3rem;
}

.feature-item {
  width: 33%;
  // text-align: left;

  &:last-child {
    .gradient-bg {
      transform: translate(-50%, -50%) rotate(-50deg);
      animation: none;
    }
  }

  .image-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 1.5rem;
    isolation: isolate;
    border: 1px solid #e7e7e7;

    &:hover {
      .gradient-bg {
        animation-play-state: running;
      }
    }

    .feature-img {
      position: relative;
      display: block;
      width: 100%;
    }
  }

  h3 {
    color: $darkBlackColor;
    font-size: $largeFontSize;
    margin: 1.75rem 0 1rem;
  }

  p {
    font-size: 1rem;
    color: $greyColor;
    font-weight: $normalFontWeight;
    opacity: $lightOpacity;
  }
}

@keyframes rotateGradient {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  50% {
    transform: translate(-50%, -50%) rotate(-50deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

.cta-wrapper {
  margin: 4rem 0;

  .btn {
    margin-top: 1.5rem;
  }
}

.integration-features {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 2.5%;

  .info-title {
    text-align: left;
    width: 50%;
    margin-right: 5rem;
  }

  .logos-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;

    img {
      height: 1.25rem;
      padding: 0.75rem 2rem;
      border-radius: 2rem;
      border: 1px solid darken(#f9f9f9, 10%);
      background-color: #f9f9f9;
      margin-right: 1rem;
    }
  }
}

.usp-list {
  text-align: left;
  width: calc(50% - 5rem);
}

.usp-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    color: $darkBlackColor;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    color: $greyColor;
    font-weight: $normalFontWeight;
    opacity: $lightOpacity;
  }

  .details {
    flex: 1;
  }

  .icon {
    position: relative;
    background: $emeraldColor;
    height: 13.5px;
    width: 13.5px;
    box-shadow: 0 0 0.7rem 0.05rem #8fedbc;
    border-radius: 50%;
    border: 2px solid #acf0cb;
    margin-top: 0.5rem;
    margin-right: 2.25rem;
  }
}

.info-wrapper {
  text-align: left;
  width: calc(50% - 5rem);

  > p {
    position: relative;
    color: $greyColor;
    font-size: $largeFontSize;
    font-weight: $normalFontWeight;
    opacity: $mediumOpacity;
    margin: 2rem auto;
  }

  .usp-list {
    width: auto;

    .usp-item {
      margin-bottom: 0;

      .icon {
        margin-right: 1.25rem;
      }

      h3 {
        font-weight: 400;
        color: $greyColor;
        font-size: $mediumFontSize;
        font-weight: $normalFontWeight;
        opacity: $mediumOpacity;
      }
    }
  }
}

.widget-feature-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 9rem;

  &.flip {
    flex-direction: row-reverse;

    .info-wrapper {
      margin-right: 0;
      margin-left: 5rem;
    }
  }

  .info-wrapper {
    margin-right: 5rem;

    p {
      font-size: $normalFontSize;
    }

    .main-title {
      font-size: 2.5rem;

      b {
        font-size: 3.5rem !important;
      }
    }
  }

  .feature-img-wrapper {
    position: relative;
    overflow: hidden;
    width: 50%;
    border-radius: 1.5rem;
    isolation: isolate;
    border: 1px solid #e7e7e7;

    &.feature-img-alt {
      border: none;
      border-radius: 0;
      overflow: visible;

      .gradient-bg {
        opacity: 0.75;
        width: 110%;
        height: 100%;
        min-width: 0;
        min-height: 0;
      }
    }

    .feature-img {
      position: relative;
      display: block;
      width: calc(100%);
    }
  }
}

@media screen and (max-width: 800px) {
  .integrations-wrapper {
    padding: 0 1.5rem 6rem;

    .info-title {
      p {
        width: auto;
      }
    }

    .main-title {
      position: relative;
      font-size: 1.5rem !important;
      line-height: 1.1 !important;
      z-index: 2 !important;

      b {
        font-size: 2rem !important;
      }
    }
  }

  .widget-list {
    margin: 0 -2rem;

    .widget-features-wrapper {
      display: block;
      white-space: nowrap;
      overflow-x: scroll;
    }

    .feature-item {
      display: inline-block;
      background: $whiteColor;
      padding: 1.5rem;
      border: 1px solid #ececec;
      border-radius: 1.5rem;
      width: calc(75% - 3rem);
      vertical-align: top;
      margin-right: 1rem;
      white-space: normal;
      margin-bottom: 2.5rem;

      &:first-child {
        margin-left: 2rem;
      }

      h3 {
        margin-top: 0;
        font-size: $normalFontSize;
      }
      
      p {
        font-size: $smallFontSize;
      }
    }
  }

  .integration-logos {
    img {
      height: auto;
      width: 5rem;
      padding: 1rem 2rem;
    }
  }

  .features-list {
    display: block;
    margin-top: 3rem;
  }

  .feature-item {
    width: auto;
    text-align: center;
    margin-bottom: 2.5rem;
  }

  .integration-features {
    display: block;
    margin: 0;

    .info-title {
      width: auto;
      margin-right: 0;
    }

    .usp-list {
      width: auto;
      margin-top: 3rem;

      .usp-item {
        .icon {
          margin-right: 1rem;
        }
      }
    }
  }

  .widget-feature-item {
    margin-top: 3rem;
    flex-direction: column-reverse !important;

    .info-wrapper {
      margin-right: 0;
      margin-left: 0 !important;
      width: auto !important;

      .main-title {
        position: relative;
        font-size: 1.5rem !important;
        line-height: 1.1 !important;
        z-index: 2 !important;

        b {
          font-size: 2rem !important;
        }
      }
    }

    .feature-img-wrapper {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  .cta-wrapper {
    padding-bottom: 3rem;
  }
}
</style>
            