<template>
  <div>
    <p class="subtitle">
      Provide delightful on-brand customer support that turns customers into
      brand advocates.
    </p>

    <div class="btn-wrapper">
      <button class="btn btn-primary" @click="createAccount">
        Start 7-day free trial
      </button>
      <div class="demo-info">
        <span>No credit cards. 30-day money back guarantee</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroDescription",
  components: {},
  methods: {
    createAccount() {
      this.trackAnalyticsEvent("demo_btn", { source: "home_hero" });

      this.$store.commit("updateDemoData", {
        source: "hero",
        contactType: "demo",
      });
      this.$router.push({ name: "CreateAccount" });
    },
  },
};
</script>

<style scoped lang="scss">
.subtitle {
  display: none;
  color: #e6ddff;
  font-family: $bodyFont;
  font-weight: 300;
  font-size: 1.25rem;
  letter-spacing: 0.015rem;
  line-height: 1.7;

  span {
    font-size: 2rem;
    margin-left: 0.5rem;
    display: inline-block;
    vertical-align: middle;
    margin-top: -5px;
  }

  b {
    color: $whiteColor;
    font-weight: $boldFontWeight;
  }
}

.btn-wrapper {
  margin-top: 1.85rem;

  .btn {
    font-size: 1.15rem;
  }

  .demo-info {
    margin-top: 0.85rem;

    label {
      display: block;
      color: #e6ddff;
      font-size: $smallestFontSize;
      opacity: $lightOpacity;
      margin-bottom: 0.15rem;
    }
  }

  span {
    color: #958bb0;
  }
}

@media screen and (max-width: 800px) {
  .subtitle {
    font-size: 1.05rem;
    text-align: center;
  }
  .btn-wrapper {
    text-align: center;
    display: block;
    margin-top: 2rem;

    .btn {
      display: table;
      margin: 2rem auto .5rem;
      font-size: $normalFontSize;
    }
  }

  .demo-info span {
    font-size: $smallerFontSize;
  }
}
</style>