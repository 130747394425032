<template>
  <div>
    <div class="success-wrapper">
      <div class="modal">
        <em>✨</em>
        <h3>Demo will be <b>ready in 48 hours</b></h3>
        <p>
          Our team will create your AI support for your brand. We will
          reach out to you within 48 hours on your email
        </p>
      </div>
    </div>
  </div>
</template>
        
<script>
export default {
  name: "SuccessPage",
  data() {
    return {};
  },
  components: {},
  computed: {},
  async created() {},
  methods: {},
};
</script>
        
<style scoped lang="scss">
.success-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 100%;
  background-image: url(@/assets/images/thankyou.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.modal {
  opacity: 0;
  display: block;
  position: fixed;
  background: rgba(15, 15, 15, 0.87);
  border: 1px solid #e9e9e9;
  padding: 2rem;
  border-radius: 1.5rem;
  width: 28rem;
  box-shadow: 0 0.5rem 2rem -0.15rem #8f8f8f;
  backdrop-filter: blur(15px);
  transform: translate3d(0, 0, 0);

  transform: translateY(0);
  animation: animateModal forwards 0.3s;
  animation-delay: 0.15s;

  h3 {
    display: table;
    position: relative;
    color: $whiteColor;
    font-weight: $lightFontWeight;
    font-size: 1.75rem;
    line-height: 1.5;
    font-weight: $normalFontWeight;
    margin: 0 auto 1rem;
  }

  p {
    color: $whiteColor;
    font-size: 1rem;
    font-weight: $lightFontWeight;
    opacity: $lightOpacity;
  }
}

@keyframes animateModal {
  0% {
    transform: translateY(calc(-50% + 1rem)) translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(-50%) translateX(-50%);
    opacity: 1;
  }
}

@media screen and (max-width: 800px) {
  .modal {
    width: calc(100% - 4rem - 2rem);
  }
}
</style>