<template>
  <div class="form-wrapper">
    <!--START: Contact Form-->
    <form v-if="!showOptions.success" v-on:submit.prevent="saveCustomer()">
      <FormBuilder :fields="fields"></FormBuilder>
      <button type="submit" class="btn btn-primary">Get In Touch</button>
      <!--START: Disclaimer-->
      <span class="disclaimer"
        >Our team will get back to your query within 48 hours</span
      >
      <!--END: Disclaimer-->
    </form>
    <!--END: Contact Form-->

    <!--START: Success Wrapper-->
    <div v-else class="success-wrapper">
      <em>✨</em>
      <h3>We've got your message</h3>
      <p>
        Thanks so much :) Our team will reach out to you within 48 hours on your
        email
      </p>
    </div>
    <!--END: Success Wrapper-->

    <!--START: Line Loader-->
    <LineLoader :show="savingCustomer"></LineLoader>
    <!--END: Line Loader-->
  </div>
</template>
      
<script>
//Import components
import FormBuilder from "./FormBuilder";
import LineLoader from "@/components/LineLoader";

// Importing Services
import { CustomerService } from "@/services";

export default {
  name: "ContactForm",
  data() {
    return {
      showOptions: {
        success: false,
      },
      savingCustomer: false,
      fields: {
        email: {
          type: "email",
          title: "Work Email",
          hasError: false,
          required: true,
          placeholder: "Your email address",
          value: "",
        },
        shopifyStore: {
          type: "text",
          title: "Website URL",
          hasError: false,
          required: true,
          placeholder: "Your website URL",
          value: "",
        },
        message: {
          type: "textarea",
          title: "Your Message",
          hasError: false,
          required: false,
          placeholder: "Anything you'd want us to know...",
          value: "",
        },
      },
    };
  },
  computed: {
    queryParameters() {
      return this.$store.getters.queryParameters;
    },
  },
  components: {
    FormBuilder,
    LineLoader,
  },
  mounted() {},
  methods: {
    // Construct the payload
    constructPayload() {
      const payload = {
        contactType: "contact",
        source: "Contact Page",
        ...this.parseData(this.fields),
        queryParameters: JSON.stringify(this.queryParameters),
      };

      return payload;
    },

    async saveCustomer() {
      if (!this.savingCustomer) {
        this.savingCustomer = true;
        if (this.validateForm(this.fields)) {
          const payload = this.constructPayload();

          this.trackAnalyticsEvent("contact_success", payload);
          await CustomerService.SaveCustomer(payload);

          this.showOptions.success = true;
        }

        this.savingCustomer = false;
      }
    },
  },
};
</script>
      
<style scoped lang="scss">
.form-wrapper {
  padding: 2rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid #ececec;
  box-shadow: 0 0rem 1rem -0.15rem rgba(111, 110, 110, 0.15);
  backdrop-filter: blur(15px);
  transform: translate3d(0, 0, 0);

  .btn {
    display: block;
    width: 100%;
  }
}

.disclaimer {
  display: block;
  margin-top: 0.5rem;
  font-size: $smallerFontSize;
  color: lighten($darkBlackColor, 15%);
  text-align: center;

  :deep(a) {
    color: $blackColor;
    font-weight: $boldFontWeight;
    text-decoration: underline;
  }
}

.success-wrapper {
  display: block;

  h3 {
    display: table;
    position: relative;
    color: $darkBlackColor;
    font-size: 1.5rem;
    line-height: 1.5;
    margin: 0 auto 1rem;
  }

  p {
    color: $darkBlackColor;
    font-size: 1rem;
    opacity: $lightOpacity;
  }
}

@media screen and (max-width: 800px) {
  .success-wrapper {
  display: block;

  h3 {
    font-size: 1.25rem;
    margin: 0 auto .5rem;
  }

  p {
    font-size: $smallFontSize;
  }
}
}
</style>
      