<template>
  <div v-for="(input, i) in fields" :key="i" class="input-field">
    <label>{{ input.title }}</label>
    <textarea
      v-if="input.type === 'textarea'"
      ref="inputField"
      v-model="input.value"
      :type="input.type"
      :placeholder="input.placeholder"
      :class="{ error: input.hasError }"
      resize="false"
      @change="inputChanged(input)"
    ></textarea>
    <input
      v-else
      ref="inputField"
      v-model="input.value"
      :type="input.type"
      :placeholder="input.placeholder"
      :class="{ error: input.hasError }"
      spellcheck="false"
      @change="inputChanged(input)"
    />
  </div>
</template>

<script>
export default {
  name: "FormBuilder",
  data() {
    return {};
  },
  props: { fields: { type: Object, required: true } },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
      
<style scoped lang="scss">
</style>
      