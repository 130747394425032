<template>
  <div class="ssp-block">
    <div class="gradient-word gradient-title-header">
      Customer-Centric AI Support
    </div>
    <h2 class="main-title">
      <b>Amazing support</b>that keeps <i>every customer</i> happy
    </h2>

    <!--START: SSP Hero-->
    <div class="integration-wrapper">
      <div class="usp-list">
        <div
          class="usp-item"
          v-for="feature in shopifyFeatures"
          :key="feature._id"
        >
          <div class="icon">
            <img :src="getFeatureImage(feature.icon)" :alt="feature.title" />
          </div>
          <div class="details">
            <h3>{{ feature.title }}</h3>
            <p>{{ feature.description }}</p>
          </div>
        </div>
      </div>
      <div class="chat-example-wrapper">
        <span class="gradient-circle"></span>
        <img
          class="chat-asset"
          src="@/assets/images/shopify-features/chat-example.png"
          alt="Shopify AI Chat"
        />
      </div>
    </div>
    <!--END: SSP Hero-->

    <!--START: Quote-->
    <div class="quote-wrapper">
      <h2 class="main-title">
        <i>Memorable brands</i> provide <b>delightful support</b>
      </h2>
      <p>
        Macha AI can add value to more than 90% of of your customer support
        interactions. Leverage AI to add more empathy, speed, context and
        delight to every conversation.
      </p>

      <div class="btn-wrapper">
        <button class="btn btn-primary" @click="createAccount">
          Start 7-day free trial
        </button>
        <div class="demo-info">
          <span>No credit cards. 30-day money back guarantee</span>
        </div>
      </div>
    </div>
    <!--END: Quote-->
  </div>
</template>
          
<script>
export default {
  name: "SSPFeatures",
  data() {
    return {
      shopifyFeatures: [
        {
          icon: "workflows",
          title: "Self-service that customers love",
          description:
            "Customers can instantly resolve a large number of queries with AI powered support that's available 24/7",
        },
        {
          icon: "ai-commerce",
          title: "An AI that undertands your customers",
          description:
            "With deep contextualization, Macha AI always keeps the customer at it's core when suggesting possible actions and tasks as resolutions",
        },
        {
          icon: "brand-voice",
          title: "Brand voice that's consistent",
          description:
            "Your customers love your brand and what it stands for; with Macha, your support will be reflective of a voice that's 100% you",
        },
        {
          icon: "translate",
          title: "Support in every language",
          description:
            "Our AI support effortlessly engages with your customers in over 100 languages, all in real-time, enabling amazing conversations.",
        },
      ],
    };
  },
  components: {},
  methods: {
    createAccount() {
      this.trackAnalyticsEvent("demo_btn", { source: "quote_cta" });

      this.$store.commit("updateDemoData", {
        source: "hero",
        contactType: "demo",
      });
      this.$router.push({ name: "CreateAccount" });
    },

    getFeatureImage(image) {
      return require(`@/assets/images/ssp-features/${image}.png`);
    },
  },
};
</script>
          
<style scoped lang="scss">
.section-number {
  opacity: 0.25;
}
.ssp-block {
  position: relative;
  text-align: center;
  margin: 3rem 0 5rem;

  .main-title {
    color: $whiteColor;
    -webkit-text-fill-color: transparent;
    background-image: radial-gradient(circle, #fff, rgba(255, 255, 255, 0.35));
    -webkit-background-clip: text;
    background-clip: text;
    font-weight: $lightFontWeight;
    font-family: "Baskervville";
    font-size: 2.75rem;
    line-height: 1.25;

    b {
      display: block;
      font-size: 4.5rem;
      font-family: $titleFont;
      text-transform: uppercase;
      letter-spacing: -2px;
      line-height: 1;
      margin-top: 0.25rem;
      margin-bottom: -0.5rem;
    }
  }
}

.gradient-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 140%;
  min-width: 140%;
  filter: blur(20px);
  transform: translate3d(0, 0, 0);

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  object-fit: cover;
  animation: rotateGradient 10s infinite;
  animation-play-state: paused;
  animation-timing-function: ease-in;
}

.features-list {
  margin: 5rem 0 5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5rem;

  .gradient-bg {
    opacity: 0.75;
  }
}

.integration-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 6rem 0 0;

  .usp-list {
    flex: 1;
    text-align: left;
    width: calc(100% - 30rem - 10rem);
  }

  .chat-example-wrapper {
    position: relative;
    width: 30rem;
    margin-left: 10rem;

    .gradient-circle {
      position: absolute;
      background-color: #7443ff;
      top: calc(50% + 1rem);
      left: calc(50% - 1.5rem);
      border-radius: 50%;
      height: 75%;
      width: 55%;
      filter: blur(100px);
      transform: translate(-50%, -50%) translate3d(0, 0, 0);
    }

    .chat-asset {
      position: relative;
      display: block;
      width: 100%;
    }
  }
}

.usp-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 3.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    color: $whiteColor;
    font-weight: $mediumFontWeight;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    margin-top: 0.25rem;
  }

  p {
    color: $lightWhiteColor;
    font-weight: $lightFontWeight;
    font-size: 1rem;
  }

  .icon {
    display: block;
    position: relative;
    background: rgba(36, 34, 43, 0.25);
    border: 1px solid #414141;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    transform: translate3d(0, 0, 0);

    padding: 0.55rem;
    border-radius: 0.5rem;
    margin: 0.25rem 1.5rem 0 0;

    img {
      width: 1.5rem;
      height: auto;
    }
  }
}

.badge {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding: 0.5rem 1.25rem;
  border-radius: 1.5rem;
  border: 1px solid #444;
  margin-bottom: 3rem;

  img {
    display: inline-block;
    width: 1.25rem;
    margin-right: 0.5rem;
  }

  span {
    color: #a4a4a4;
    font-size: $smallFontSize;
  }
}

.quote-wrapper {
  margin: 9rem auto 0;

  p {
    font-size: $mediumFontSize;
    color: $lightWhiteColor;
    font-weight: $lightFontWeight;
    margin: 2rem 12.5% 4rem;
  }

  .main-title span {
    display: block;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}

.demo-info {
  margin-top: 0.85rem;

  span {
    color: $lightWhiteColor;
  }
}

@media screen and (max-width: 800px) {
  .ssp-block {
    margin-top: 0;

    .main-title {
      position: relative;
      font-size: 1.5rem;
      line-height: 1.1;
      z-index: 2;

      b {
        font-size: 2rem;
        margin-top: 0.25rem;
        margin-bottom: 0;
      }

      .gpt-badge {
        left: calc(100% - 2.5rem);
      }
    }
  }

  .integration-wrapper {
    margin: 4rem 0 5rem;
    flex-direction: column-reverse;

    .chat-example-wrapper {
      width: 100%;
      margin-bottom: 3rem;
      margin-left: 0;
      overflow: visible;

      .gradient-bg {
        animation: none;
        min-height: auto;
        height: 80%;
      }
    }

    .usp-list {
      position: relative;
      width: 100%;
      margin: auto;
      z-index: 1;
    }

    .badge {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      border: none;

      img {
        width: 1.85rem;
        margin-right: 1rem;
      }

      span {
        font-weight: $mediumFontWeight;
        color: $whiteColor;
        font-size: $mediumFontSize;
      }
    }

    .usp-item {
      display: block;
      margin-bottom: 2rem;

      h3 {
        font-size: $mediumFontSize;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: $smallFontSize;
      }

      .icon {
        display: table;
        margin-bottom: 1rem;
      }
    }
  }

  .quote-wrapper {
    margin-top: 5rem;

    p {
      font-size: $smallFontSize;
      margin: 2rem 0 3rem;
    }
  }
}
</style>
          