<template>
  <div class="line-container">
    <div class="line-wrapper">
      <div v-for="(line, index) in lineCount" :key="index" class="line">
        <span v-if="index == 0 || index == 2" class="orb"></span>
      </div>
    </div>
  </div>
</template>
        
<script>
export default {
  name: "LineAnimation",
  data() {
    return {
      lineCount: 5,
    };
  },
  methods: {},
};
</script>
        
<style scoped lang="scss">
.line-container {
  width: 15%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(calc(-50% - 5rem));
  opacity: .75;

  &.line-right {
    left: auto;
    right: 0;
    transform: translateY(calc(-50% - 5rem)) rotateZ(180deg);
    .line {
      background-image: linear-gradient(270deg, #422264 60%, #694393 100%);
    }
  }
}

.line-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
  width: 100%;
}

.orb {
  width: 25px;
  height: 1px;
  position: absolute;
  top: 0;
  left: -25px;
  background-image: radial-gradient(
    49% 81% at 45% 47%,
    #00e5ff 22%,
    #073aff00 100%
  );
  animation: animateLine 2s ease-in-out infinite;
  transform: translateZ(0);
  opacity: 0;
  will-change: width, opacity, left;
}

.line {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(270deg, #1f1a27 60%, #442863 100%);
  background-size: 200% 100%;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;

  &:nth-child(1) .orb {
    animation-delay: 1s;
  }
}

@keyframes animateLine {
  2% {
    opacity: 1;
  }

  20% {
    width: 55px;
  }

  100% {
    left: 105%;
    width: 20px;
  }
}
</style>
        