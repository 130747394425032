<template>
  <div>
    <!--START: Header and Gradient-->
    <Header
      currentSection="zendesk"
      :theme="headerTheme"
      @createAccount="createAccount"
    ></Header>
    <!--END: Header and Gradient-->

    <!--START: Hero-->
    <Hero
      id="hero"
      class="scroll-section"
      @createAccount="createAccount"
    ></Hero>
    <!--END: Hero-->

    <div
      id="integration"
      class="white-background scroll-section integration-container"
    >
      <!--START: Features-->
      <ZendeskFeatures @createAccount="createAccount"></ZendeskFeatures>
      <!--END: Features-->

      <!--START: Customer Story-->
      <CustomerStory></CustomerStory>
      <!--END: Customer Story-->
    </div>

    <!--START: Header and Gradient-->
    <Footer></Footer>
    <!--END: Header and Gradient-->
  </div>
</template>
        
<script>
//Import components
import Header from "@/components/Integrations/Header";
import Footer from "@/components/Footer";

import Hero from "@/components/Integrations/ZendeskHero";
import ZendeskFeatures from "@/components/Integrations/ZendeskFeatures";
import CustomerStory from "@/components/Home/CustomerStory";

export default {
  name: "ZendeskIntegration",
  data() {
    return {
      headerTheme: "dark",
    };
  },

  components: {
    Header,
    Footer,
    Hero,
    ZendeskFeatures,
    CustomerStory,
  },
  async created() {
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    createAccount(data) {
      this.trackAnalyticsEvent(data.event, data.source);

      this.$store.commit("updateDemoData", {
        source: data.source.source,
        contactType: "create_account",
      });

      window.open(
        "https://www.zendesk.com/marketplace/apps/support/986669/chatgpt-ai-agent-assistant-by-macha/",
        "_blank"
      );
    },

    // Header menu selection
    onScroll() {
      const scrollY = window.scrollY + 75;
      const sections = document.querySelectorAll(".scroll-section");

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];

        if (
          section.offsetTop <= scrollY &&
          section.offsetTop + section.offsetHeight >= scrollY
        ) {
          // Header theme styles
          switch (section.id) {
            case "hero":
              this.headerTheme = "dark";
              break;
            case "integration":
              this.headerTheme = "light";
              break;
            default:
              this.headerTheme = "dark";
              break;
          }
        }
      }
    },
  },
};
</script>
        
<style scoped lang="scss">
.white-background {
  position: relative;
  background-color: $whiteColor;
}

.integration-container {
  padding-top: 8rem;
}

.info-title {
  position: relative;
  padding: 7rem 12.5% 0;
  text-align: center;
  z-index: 2;

  .main-title {
    display: table;
    position: relative;
    color: $whiteColor;
    -webkit-text-fill-color: transparent;
    background-image: radial-gradient(circle, #575757, #1d1d1d);
    -webkit-background-clip: text;
    background-clip: text;
    font-weight: $lightFontWeight;
    font-size: 2.5rem;
    line-height: 1.05;
    margin: auto;

    b {
      font-size: 3.5rem;
    }

    .gpt-badge {
      position: absolute;
      bottom: 1.25rem;
      left: calc(100% - 1.5rem);
      width: 6rem;
      transform: rotate(15deg);
    }
  }

  p {
    position: relative;
    color: $greyColor;
    font-size: $largeFontSize;
    font-weight: $normalFontWeight;
    margin: 1rem auto;
    width: 70%;

    span {
      font-weight: $boldFontWeight;
    }
  }
}

.beta-badge {
  display: table;
  margin: 0 auto 1rem;
  color: #2a9e61;
  background-color: #edfff5;
  border: 1px solid #46cb85;
  padding: 0.55rem 1.5rem;
  border-radius: 1rem;
  font-size: $smallFontSize;
  font-weight: $boldFontWeight;
}

.gradient-circle {
  position: absolute;
  background-color: #ffe7b5;
  top: -35%;
  right: -45%;
  border-radius: 50%;
  height: 55%;
  width: 85%;
  filter: blur(100px);
  transform: translate3d(0, 0, 0);

  opacity: 0.5;
}

.demo-form-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10rem 15% 0;

  .main-title {
    display: table;
    position: relative;
    color: $whiteColor;
    -webkit-text-fill-color: transparent;
    background-image: radial-gradient(circle, #575757, #1d1d1d);
    -webkit-background-clip: text;
    background-clip: text;
    font-weight: $lightFontWeight;
    font-size: 2.5rem;
    line-height: 1.05;

    :deep(b) {
      position: relative;
      -webkit-text-fill-color: transparent;
      background-image: radial-gradient(circle, #575757, #1d1d1d);
      -webkit-background-clip: text;
      background-clip: text;
    }
  }

  .info-wrapper {
    width: 50%;
    margin-right: 5rem;
  }

  .form-wrapper {
    flex: 1;
  }

  .gradient-circle {
    position: absolute;
    background-color: rgb(167, 138, 250);
    right: -45%;
    border-radius: 50%;
    top: calc(50% + 6rem);
    transform: translateY(-50%);
    height: 55%;
    width: 90%;
    filter: blur(100px);
    transform: translate3d(0, 0, 0);

    opacity: 0.5;
  }
}

.inclusions-list {
  label {
    display: block;
    font-weight: $mediumFontWeight;
    font-size: $normalFontSize;
    margin: 2.5rem 0 2rem;
  }
  .inclusion {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
  }

  :deep(svg) {
    fill: darken($emeraldColor, 30%);
    width: auto;
    height: 1rem;
    margin-right: 1rem;
    background: lighten($emeraldColor, 25%);
    border-radius: 50%;
    padding: 2px;
  }
}

:deep(footer) {
  padding-top: 5rem;
}

:deep(.testimonial-wrapper) {
  position: relative;
  margin-bottom: 0;

  &::before {
    content: "";
    position: absolute;
    display: block;
    top: 60%;
    width: 100%;
    height: 41%;
    background: #0c0c0c;
  }
}

.mobile {
  display: none;
}

@media screen and (max-width: 800px) {
  .info-title {
    padding: 5rem 1.5rem 0;
    .main-title {
      font-size: 2rem;
      b {
        font-size: 2.5rem;
      }

      .gpt-badge {
        left: calc(100% - 2.5rem);
      }
    }

    p {
      width: auto;
    }
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }

  .demo-form-wrapper {
    display: block;
    padding: 7.5rem 1.5rem 1rem;

    .info-wrapper {
      width: auto;
      margin: 0;
    }

    .main-title {
      font-size: 2rem;
      margin-bottom: 2rem;
      text-align: center;
    }

    .inclusions-list {
      margin-top: 3rem;
      label {
        margin-bottom: 1.5rem;
      }
    }
  }
}
</style>