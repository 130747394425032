import { createApp } from "vue";
import App from "./App.vue";
import routes from "./routes";
import Unicon from "vue-unicons";
import store from "@/store";

// Importing Master CSS
import "@/assets/styles/_reset.css";
import "@/assets/styles/_base.scss";

// Importing Mixin Functions
import GlobalFunctions from "@/mixins/Functions";

// Importing Font Awesome icons
import {
  uniAngleLeftB,
  uniAngleRightB,
  uniCheck,
  uniCornerUpLeft,
  uniMinus,
  uniPlus,
} from "vue-unicons/dist/icons";
Unicon.add([
  uniCheck,
  uniPlus,
  uniMinus,
  uniCornerUpLeft,
  uniAngleRightB,
  uniAngleLeftB,
]);

// Configure and deploy app
const app = createApp(App);

app.mixin(GlobalFunctions);
app.use(Unicon);
app.use(store);
app.use(routes);

app.mount("#app");
