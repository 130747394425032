<template>
  <footer>
    <img src="@/assets/images/logo-white.png" class="logo" />
    <span class="disclaimer">© AGZ Technologies Private Limited</span>

    <div class="links-wrapper">
      <router-link to="/zendesk">Zendesk App</router-link>
      <router-link to="/contact">Contact</router-link>
      <router-link to="/policies/privacy-policy">Privacy Policy</router-link>
      <router-link to="/policies/refund-policy"
        >Refunds &amp; Cancellations</router-link
      >
      <router-link to="/policies/terms-of-use"
        >Terms &amp; Conditions</router-link
      >
    </div>
  </footer>
</template>
  
<script>
export default {
  name: "GlobalFooter",
};
</script>
  
<style scoped lang="scss">
footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem 5%;
  background: #0c0c0c;
  border-top: 1px solid #222;

  .logo {
    display: block;
    width: 6.25rem;
    margin-left: 0.5rem;
    margin-top: -5px;
    margin-right: 2rem;
  }

  .disclaimer {
    color: $lightWhiteColor;
    font-size: $smallerFontSize;
  }

  .links-wrapper {
    flex: 1;
    text-align: right;

    a {
      color: $lightWhiteColor;
      font-size: $smallFontSize;
      padding: 0 0.85rem;
    }
  }
}

@media screen and (max-width: 800px) {
  footer {
    display: block;
    padding: 2rem 1.5rem;
    text-align: center;

    .logo {
      width: 5.75rem;
      margin: 0 auto 0.5rem;
    }

    .disclaimer {
      font-size: $smallestFontSize;
    }

    .links-wrapper {
      text-align: center;
      border-top: 1px solid #1e1e1e;
      margin-top: 2rem;
      padding-top: 1rem;

      a {
        display: block;
        font-size: $smallFontSize;
        margin: 1rem 0;
      }
    }
  }
}
</style>
  