<template>
  <div>
    <h1>Refunds &amp; Cancellations</h1>
    <p>
      AGZ Technologies Private Limited reserves the right to not accept or
      cancel your subscription. AGZ TECHNOLOGIES PRIVATE LIMITED shall reach a
      decision after verifying the validity of such claim, and reserves the
      right to suspend Your account pending such decision. Any decision reached
      by AGZ TECHNOLOGIES PRIVATE LIMITED shall be final and binding, and shall
      not be subject to review.
    </p>
    <p>
      Claims may be entertained on the grounds that your account contains
      content that is:
    </p>
    <ol>
      <li>
        harmful, offensive, insensitive, inaccurate, falsely misrepresents, or
        otherwise damages the reputation of the Website;
      </li>
      <li>contrary to the applicable laws of India;</li>
      <li>
        deceives clients about the identity or activities of the User or
        misleads the clients about the origin of the materials posted&nbsp;
      </li>
      <li>
        consists of any material that infringes the intellectual property, right
        of privacy or right of publicity of any other person, or otherwise
        purports to be under license from the owner when no license has been
        issued;
      </li>
      <li>harasses or advocates the harassment of another person;</li>
      <li>
        promotes illegal activity or conduct that is abusive, threatening,
        obscene, defamatory, or libellous;
      </li>
      <li>
        offensive to an extent that would cause AGZ TECHNOLOGIES PRIVATE LIMITED
        to lose the support of its suppliers and/or stakeholders.
      </li>
    </ol>
    <p><br /></p>
    <p>
      Services provided by AGZ TECHNOLOGIES PRIVATE LIMITED are non-refundable,
      and You agree and consent to the same. AGZ TECHNOLOGIES PRIVATE LIMITED
      provides that it will take all necessary steps to rectify any issues or
      errors that arise during Your use of the Website, upon notification of the
      same by You. If AGZ TECHNOLOGIES PRIVATE LIMITED comes to the conclusion
      that the issue or error cannot be resolved and has arisen by AGZ
      TECHNOLOGIES PRIVATE LIMITED’s sole fault, then AGZ TECHNOLOGIES PRIVATE
      LIMITED may choose to refund the amount paid, if any, for the service
      purchased. All decisions in this regard by AGZ TECHNOLOGIES PRIVATE
      LIMITED shall be final and binding, and shall not be subject to review.
    </p>
  </div>
</template>
  
<script>
export default {
  name: "RefundPolicy",
};
</script>