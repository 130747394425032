<template>
  <div class="success-manager-wrapper">
    <img
      src="@/assets/images/success-manager.png"
      class="manager-asset"
      alt="Dedicated Co-Founder"
    />
    <div class="info-wrapper">
      <h3>Dedicated success <em>manager</em> co-founder</h3>
      <p>
        We'll be there on your journey from pre-launch to post-launch. Abbas,
        our co-founder, will assist you with on-boarding, setup, audits and
        optimizations post-audit.
      </p>
    </div>
  </div>
</template>
            
<script>
export default {
  name: "SuccessManager",
  data() {
    return {};
  },
  methods: {},
};
</script>
            
<style scoped lang="scss">
.success-manager-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 45%;
  margin: 3rem auto 5rem;

  .manager-asset {
    width: 4rem;
    border-radius: 50%;
    border: 2px solid #fbdb60;
  }
}
.info-wrapper {
  margin-left: 2rem;

  h3 {
    color: $blackColor;
    font-size: $normalFontSize;

    em {
      display: inline-block;
      position: relative;

      &::after {
        display: block;
        content: "";
        position: absolute;
        height: 2px;
        width: 100%;
        background: $blackColor;
        border-radius: 0.5rem;
        top: 0.85rem;
        transform: translateY(-50%) rotate(-2deg);
        z-index: 1;
      }
    }
  }
  p {
    color: lighten($darkBlackColor, 35%);
    font-weight: $normalFontWeight;
  }
}

@media screen and (max-width: 800px) {
  .success-manager-wrapper {
    width: auto;
    margin: 4rem 1.5rem;
    text-align: center;
    display: block;

    .manager-asset {
      width: 5rem;
      margin-bottom: 1rem;
    }

    .info-wrapper {
      margin-left: 0;
    }
  }
}
</style>
            