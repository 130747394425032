<template>
  <div class="faq-wrapper">
    <div class="info-title">
      <h2 class="main-title">Frequently Asked Questions</h2>
    </div>

    <!--START: FAQ List-->
    <ul class="faq-list">
      <li
        v-for="(item, index) in faqs"
        :key="index"
        class="faq-item"
        :class="{ selected: index == selectedQuestion }"
        @click="toggleQuestion(index)"
      >
        <h3 class="question">
          <span>{{ item.question }}</span>
          <unicon name="plus"></unicon>
        </h3>
        <div class="answer-wrapper">
          <p class="answer" v-html="item.answer"></p>
        </div>
      </li>
    </ul>
    <!--END: FAQ List-->
  </div>
</template>
              
<script>
export default {
  name: "FAQs",
  props: { faqs: Array },
  data() {
    return {
      selectedQuestion: -1,
    };
  },
  methods: {
    toggleQuestion(index) {
      if (this.selectedQuestion == index) this.selectedQuestion = -1;
      else this.selectedQuestion = index;
    },
  },
};
</script>
              
<style scoped lang="scss">
.faq-wrapper {
  margin: 10rem 12.5% 10rem;
}
.main-title {
  position: relative;
  color: $whiteColor;
  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(circle at bottom right, #575757, #1d1d1d);
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: $lightFontWeight;
  font-family: "Baskervville";
  font-size: 2.5rem;
  line-height: 1.25;

  :deep(b) {
    display: block;
    font-size: 4.5rem;
    font-family: $titleFont;
    text-transform: uppercase;
    letter-spacing: -2px;
    margin-bottom: -0.5rem;
  }
}

.info-title {
  position: relative;
  text-align: center;
}

.faq-list {
  margin: 3rem 15% 0;
}

.faq-item {
  padding: 1.5rem 0;
  border-bottom: 1px solid #ececec;
  transition: all 0.3s;

  &:last-child {
    border-bottom: none;
  }
}

.question {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $darkBlackColor;
  font-size: $mediumFontSize;
  font-weight: $normalFontWeight;
  opacity: $mediumOpacity;
  transition: all 0.3s;

  span {
    flex: 1;
    margin-right: 1rem;
  }

  :deep(svg) {
    height: auto;
    width: 1.25rem;
    fill: lighten($darkBlackColor, 45%);
    transition: all 0.3s;
  }
}

.answer-wrapper {
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s;

  .answer {
    font-size: $normalFontSize;
    margin: 1.5rem 0 1rem;
    width: calc(100% - 2rem);
    opacity: $mediumOpacity;
  }
}

.faq-item {
  &:not(.selected):hover {
    cursor: pointer;
    border-bottom-color: #aaa;

    .question {
      opacity: 1;

      :deep(svg) {
        fill: $purpleColor;
      }
    }
  }

  &.selected {
    .question {
      color: $darkBlackColor;
    }
    .answer-wrapper {
      max-height: 20rem;
    }
  }
}

@media screen and (max-width: 800px) {
  .faq-wrapper {
    margin: 5rem 1.5rem;
  }
  .info-title {
    .main-title {
      position: relative;
      font-size: 1.5rem;
      line-height: 1.1;
      z-index: 2;

      b {
        font-size: 2.5rem;
        margin-top: 0.25rem;
        margin-bottom: 0;
      }
    }
  }

  .faq-list {
    margin: 3rem 0;
  }
}
</style>
              