<template>
  <!--START: AI Features-->
  <div class="ai-wrapper">
    <div class="image-wrapper">
      <span class="gradient-circle"></span>
      <img
        class="ai-asset"
        src="@/assets/images/ai-feature.png"
        alt="AI Workflow"
      />
      <img
        class="ai-asset-bg"
        src="@/assets/images/ai-feature-bg.png"
        alt="AI Feature BG"
      />
    </div>
    <div class="info-wrapper">
      <div class="info-title">
        <h2 class="main-title">
          <b>AI Support</b>
          that's <i> built for you</i>
        </h2>
      </div>
      <p>
        At it's core, Macha AI is built to solve complex support workflows,
        allowing brands to deliver amazing support experiences.
      </p>
      <div class="usp-list">
        <div class="usp-item" v-for="feature in features" :key="feature._id">
          <div class="icon"></div>
          <div class="details">
            <h3>{{ feature.title }}</h3>
            <p>{{ feature.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--END: AI Features-->
</template>
        
<script>
export default {
  name: "AIFeatures",
  data() {
    return {
      features: [
        {
          title: "Understands complex queries",
          description:
            "Understands the customers intent, reasons and category of questions incredibly well",
        },
        {
          title: "Expansive and holistic knowledge base",
          description:
            "Our proprietary AI accurately looks through all the data, to identify the best course of action",
        },
        {
          title: "Takes the right action, everytime",
          description:
            "Along with human input, the AI suggests the best solution - from recommendations to processing tasks",
        },
      ],
    };
  },
  methods: {},
};
</script>
        
<style scoped lang="scss">
.ai-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.5rem;
}

.main-title {
  position: relative;
  color: $whiteColor;
  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(circle at bottom right, #575757, #1d1d1d);
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: $lightFontWeight;
  font-family: "Baskervville";
  font-size: 2.75rem;
  line-height: 1.25;

  b {
    display: block;
    font-size: 4.5rem;
    font-family: $titleFont;
    text-transform: uppercase;
    letter-spacing: -2px;
    margin-bottom: -0.5rem;
    line-height: 1.1;
  }
}

.usp-list {
  text-align: left;
  width: calc(50% - 5rem);
}

.usp-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    color: $darkBlackColor;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    color: $greyColor;
    font-weight: $normalFontWeight;
    opacity: $lightOpacity;
  }

  .details {
    flex: 1;
  }

  .icon {
    position: relative;
    background: $emeraldColor;
    height: 13.5px;
    width: 13.5px;
    box-shadow: 0 0 0.7rem 0.05rem #8fedbc;
    border-radius: 50%;
    border: 2px solid #acf0cb;
    margin-top: 0.5rem;
    margin-right: 2.25rem;
  }
}

.info-wrapper {
  text-align: left;
  width: calc(50% - 5rem);

  > p {
    position: relative;
    color: $greyColor;
    font-size: $largeFontSize;
    font-weight: $normalFontWeight;
    opacity: $mediumOpacity;
    margin: 2rem auto 3.5rem;
  }

  .usp-list {
    width: auto;

    .usp-item {
      margin-bottom: 2rem;

      .icon {
        margin-right: 1.25rem;
      }

      h3 {
        font-weight: 400;
        font-size: $normalFontSize;
        font-weight: $boldFontWeight;
        opacity: $mediumOpacity;
        margin-bottom: 0.25rem;
      }
    }
  }
}

.image-wrapper {
  position: relative;
  width: 50%;
  margin-right: 5rem;

  .gradient-circle {
    position: absolute;
    background-color: #e6ddff;
    bottom: -5rem;
    left: -10rem;
    border-radius: 50%;
    height: 95%;
    width: 85%;
    filter: blur(100px);
    z-index: 1;
    mix-blend-mode: multiply;
  }

  .ai-asset {
    position: relative;
    display: block;
    width: 100%;
    z-index: 2;
  }

  .ai-asset-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    mix-blend-mode: multiply;
    z-index: 0;
  }
}

@media screen and (max-width: 800px) {
  .ai-wrapper {
    flex-direction: column;
    margin-top: 0;
  }

  .image-wrapper {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
    margin-bottom: 3rem;
    width: calc(100% + 2rem);
  }

  .info-wrapper {
    width: auto;

    > p {
      font-size: $normalFontSize;
      margin: 1rem 0 3rem;
    }
  }

  .main-title {
    position: relative;
    font-size: 1.5rem;
    line-height: 1.1;
    z-index: 2;

    b {
      font-size: 2.5rem;
    }
  }

  .usp-item {
    .icon {
      width: 9px;
      height: 9px;
      margin-right: 1rem !important;
    }

    p {
      font-size: $smallFontSize;
    }
  }
}
</style>  