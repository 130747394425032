<template>
  <!--START: CTA Wrapper-->
  <div class="cta-wrapper">
    <h2 class="main-title">
      <b>Better support.</b> Happier Customers. Improved Loyalty.
    </h2>
    <div class="btn-wrapper">
      <button class="btn btn-primary" @click="createAccount">
        Start 7-day free trial
      </button>
      <div class="demo-info">
        <label>No strings attached</label>
        <span>30-day money back gurantee</span>
      </div>
    </div>
  </div>
  <!--END: CTA Wrapper-->
</template>

<script>
export default {
  name: "RegistrationCTA",
  data() {
    return {};
  },
  methods: {
    createAccount() {
      this.trackAnalyticsEvent("demo_btn", { source: "footer" });

      this.$store.commit("updateDemoData", {
        source: "footer",
        contactType: "demo",
      });
      this.$router.push({ name: "CreateAccount" });
    },
  },
};
</script>

<style scoped lang="scss">
.cta-wrapper {
  position: relative;

  p {
    color: $whiteColor;
    font-size: $largerFontSize;
    font-weight: $lightFontWeight;
    opacity: $lightOpacity;
  }

  .info-wrapper {
    width: 40%;
    margin-right: 10rem;
  }

  .main-title {
    color: $whiteColor;
    -webkit-text-fill-color: transparent;
    background-image: radial-gradient(circle, #fff, rgba(255, 255, 255, 0.35));
    -webkit-background-clip: text;
    background-clip: text;
    font-weight: $lightFontWeight;
    font-family: "Baskervville";
    font-size: 2.75rem;
    line-height: 1.25;
    margin-bottom: 3rem;

    b {
      display: block;
      font-size: 4.25rem;
      font-family: $titleFont;
      text-transform: uppercase;
      display: inline-block;
      letter-spacing: -2px;
      line-height: 1.1;
      margin-bottom: -1.25rem;
    }
  }

  .btn-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4rem;

    .demo-info {
      label {
        display: block;
        color: $lightWhiteColor;
        font-size: $smallerFontSize;
        opacity: $lightOpacity;
        margin-bottom: 0.15rem;
      }
    }

    span {
      color: $lightWhiteColor;
    }
  }

  .btn-primary {
    color: $darkBlackColor;
    background: #f9f9f9;
    font-size: $mediumFontSize;
    border-color: lighten($whiteColor, 5%);
    margin-right: 2rem;
  }
}

@media screen and (max-width: 800px) {
  .cta-wrapper {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 2rem;

    .main-title {
      position: relative;
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.1;
      z-index: 2;
      margin-bottom: 1.5rem;

      b {
        font-size: 2.5rem;
      }
    }

    .btn-wrapper {
      display: block;
      margin-bottom: 3rem;
      margin-top: 0;

      .btn {
        font-size: $normalFontSize;
        display: table;
        margin: 0 auto 1.5rem;
      }

      .demo-info {
        text-align: center;
      }
    }
  }
}
</style>
          