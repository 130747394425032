<template>
  <div class="gradient-container">
    <div class="gradient-color"></div>
    <div class="gradient-color"></div>
    <div class="gradient-color"></div>
    <div class="gradient-color"></div>
    <div class="gradient-backdrop"></div>
  </div>
</template>
  
<script>
export default {
  name: "GradientBackground",
};
</script>
  
<style scoped>
.gradient-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .85;
  display: flex;
  background: var(--gradient-color-2);
  flex-wrap: wrap;
  overflow: hidden;
  z-index: -1;
}
.gradient-color {
  width: 50%;
  height: 50%;
  display: flex;
  border-radius: 30% 80% 75% 40% / 40% 40% 70% 50%;
  /* animation-name: animate; */
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.gradient-color:nth-child(1) {
  background-color: var(--gradient-color-1);
  animation-direction: backwards;
}
.gradient-color:nth-child(2) {
  background-color: var(--gradient-color-2);
}
.gradient-color:nth-child(3) {
  background-color: var(--gradient-color-3);
  animation-direction: backwards;
}
.gradient-color:nth-child(4) {
  background-color: var(--gradient-color-4);
  animation: none;
  z-index: 1;
  filter: blur(100px);
  transform: translate3d(0, 0, 0);

}
.gradient-backdrop {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
  backdrop-filter: var(--blur);
  -webkit-backdrop-filter: var(--blur);
}

@keyframes animate {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 60%;
    transform: scale(2) rotate(0deg) translate(10%, 10%);
  }
  100% {
    border-radius: 88% 10% 22% 58% / 73% 56% 34% 77%;
    transform: scale(2) rotate(180deg) translate(10%, -10%);
  }
}
</style>
  