import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      demoData: {
        source: "",
        contactType: "demo",
      },
      queryParameters: {}
    };
  },
  mutations: {
    updateDemoData(state, data) {
      const { source, contactType } = data;
      state.demoData = { source, contactType };
    },
    updateQueryParameters(state, data) {
      state.queryParameters = data;
    },
  },
  getters: {
    demoData(state) {
      return state.demoData;
    },
    queryParameters(state) {
      return state.queryParameters;
    },
  },
});

export default store;
