<template>
  <div class="form-wrapper">
    <!--START: Contact Form-->
    <form v-on:submit.prevent="saveCustomer()">
      <FormBuilder :fields="fields"></FormBuilder>
      <button class="btn btn-primary">{{ buttonText[contactType] }}</button>
    </form>
    <!--END: Contact Form-->

    <!--START: Disclaimer-->
    <span class="disclaimer" v-html="disclaimerText[contactType]"></span>
    <!--END: Disclaimer-->

    <!--START: Line Loader-->
    <LineLoader :show="savingCustomer"></LineLoader>
    <!--END: Line Loader-->
  </div>
</template>
    
<script>
//Import components
import FormBuilder from "./FormBuilder";
import LineLoader from "@/components/LineLoader";

// Importing Services
import { CustomerService } from "@/services";

export default {
  name: "DemoForm",
  data() {
    return {
      savingCustomer: false,
      buttonText: {
        demo: "Start your 7-day free trial",
        register: "Start your 7-day free trial",
        sales: "Start your 7-day free trial",
      },
      disclaimerText: {
        demo: 'By creating a demo for your store you agree to our <a href="/policies/terms-of-use" target="_blank">Terms of Use</a>',
        register:
          'By registering with Macha you agree to our <a href="/policies/terms-of-use" target="_blank">Terms of Use</a>',
        sales:
          "Our team will reach out to you within 48 hours to schedule a demo call",
      },
      fields: {
        email: {
          type: "email",
          title: "Work Email",
          hasError: false,
          required: true,
          placeholder: "Your email address",
          value: "",
        },
        shopifyStore: {
          type: "text",
          title: "Website URL",
          hasError: false,
          required: true,
          placeholder: "Your website URL",
          value: "",
        },
      },
    };
  },
  computed: {
    contactType() {
      return this.$store.getters.demoData.contactType;
    },
    source() {
      return this.$store.getters.demoData.source;
    },
    queryParameters() {
      return this.$store.getters.queryParameters;
    },
  },
  components: { FormBuilder, LineLoader },
  methods: {
    // Construct the payload
    constructPayload() {
      const payload = {
        contactType: this.contactType,
        source: this.source,
        ...this.parseData(this.fields),
        queryParameters: JSON.stringify(this.queryParameters),
      };
      console.log(payload);

      return payload;
    },

    async saveCustomer() {
      if (!this.savingCustomer) {
        this.savingCustomer = true;
        if (this.validateForm(this.fields)) {
          const payload = this.constructPayload();

          this.trackAnalyticsEvent("demo_success", payload);
          await CustomerService.SaveCustomer(payload);
          this.$router.push({ name: "Success" });
        }

        this.savingCustomer = false;
      }
    },
  },
};
</script>
    
<style scoped lang="scss">
.form-wrapper {
  padding: 2rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid #ececec;
  box-shadow: 0 0rem 1rem -0.15rem rgba(111, 110, 110, 0.15);
  backdrop-filter: blur(15px);
  transform: translate3d(0, 0, 0);

  .btn {
    display: block;
    width: 100%;
  }
}

.disclaimer {
  display: block;
  margin-top: 2rem;
  font-size: $smallerFontSize;
  color: lighten($darkBlackColor, 15%);

  :deep(a) {
    color: $blackColor;
    font-weight: $boldFontWeight;
    text-decoration: underline;
  }
}
</style>
    