<template>
  <div class="statistics-wrapper">
    <div class="statistics-main-block">
      <!--START: CTA Wrapper-->
      <RegistrationCTA class="registration-cta"></RegistrationCTA>
      <!--END: CTA Wrapper-->

      <!--START: Statistics-->
      <div class="stats-list">
        <div class="stat-item">
          <h3>80<b>%</b></h3>
          <p>
            Most shoppers want an Amazon-like support experience, which is
            'self-serve'
          </p>
        </div>
        <div class="stat-item">
          <h3>89<b>%</b></h3>
          <p>
            Customers are more likely to make another purchase after a good
            support experience
          </p>
        </div>
        <div class="stat-item">
          <h3>60<b>%</b></h3>
          <p>Customers want good support to feel loyalty towards a brand</p>
        </div>
      </div>
      <!--END: Statistics-->

      <span class="gradient-circle"></span>
    </div>
  </div>
</template>
          
<script>
import RegistrationCTA from "../RegistrationCTA";

export default {
  name: "SupportStatistics",
  data() {
    return {};
  },
  components: { RegistrationCTA },
  methods: {},
};
</script>
          
<style scoped lang="scss">
.statistics-wrapper {
  position: relative;
  margin-top: -4rem;
  z-index: 1;

  .statistics-main-block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    z-index: 1;
    background-color: #111;
    border-top: 1px solid lighten(#111, 15%);
    border-top-left-radius: 4rem;
    border-top-right-radius: 4rem;
    overflow: hidden;
    padding: 18rem 12.5% 8rem;

    &::before {
      content: "";
      position: absolute;
      background-image: url(@/assets/images/noise.webp);
      background-position: 0 0;
      background-repeat: repeat;
      background-size: auto;
      background-attachment: fixed;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }

  .gradient-bg {
    position: absolute;
    z-index: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 4rem);
    bottom: -4px;
  }

  .gradient-circle {
    position: absolute;
    background-color: #7443ff;
    bottom: 0%;
    left: -25%;
    border-radius: 50%;
    height: 55%;
    width: 55%;
    filter: blur(100px);
    transform: translate3d(0, 0, 0);

    opacity: 0.5;
  }
}

.gradient-border {
  position: absolute;
  z-index: -1;
  left: 0;
  width: 100%;
  height: calc(100% - 4rem);
  transform: rotate(180deg);
  border-bottom-left-radius: 4rem;
  border-bottom-right-radius: 4rem;
  top: -4px;
}

.stats-list {
  position: relative;
  z-index: 2;
  .stat-item {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #636363;

    h3 {
      color: $whiteColor;
      font-size: 4.5rem;
      margin-bottom: 1rem;
      letter-spacing: -2px;
      line-height: 1;

      b:last-child {
        font-size: 2.5rem;
        font-weight: $lightFontWeight;
        margin-left: 0.5rem;
        letter-spacing: normal;
      }
    }
    p {
      font-size: $mediumFontSize;
      font-weight: $lightFontWeight;
      color: $whiteColor;
      opacity: $lightOpacity;
    }
  }
}

:deep(.cta-wrapper) {
  position: relative;
  z-index: 2;
  flex: 1;
  min-width: 45%;
  margin-right: 12.5rem;
}

@media screen and (max-width: 800px) {
  .statistics-wrapper .statistics-main-block {
    display: block;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    padding-top: 14rem;
    padding-bottom: 6rem;
  }

  .stats-list {
    .stat-item {
      display: flex;
      flex-direction: row;
      align-items: center;

      h3 {
        font-size: 2.85rem;
        margin-right: 1rem;

        b:last-child {
          font-size: 1.25rem;
          margin-left: 0.15rem;
        }
      }

      p {
        font-size: $smallFontSize;
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
}
</style>
          