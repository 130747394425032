<template>
  <div>
    <h1>Privacy Policy</h1>
    <p>
      Please read this Privacy Policy (“Policy”) carefully. This contains
      important information about your rights and obligations. This Privacy
      Policy sets out the manner in which AGZ Technologies Private Limited (“AGZ
      TECHNOLOGIES PRIVATE LIMITED”, “We”, “Us” or “Our”), the proprietor and
      owner of the getmacha.com websites (“Website”), collects, uses, maintains,
      and discloses personal information collected from users (“User”, “You” or
      “Your”) of the Website. This Policy applies to all Website services and
      related services. The provisions of this Policy shall be read in
      consonance with the Terms of Use of the Website, and shall not be
      construed to override any provisions of the Terms of Use or any other
      contract between You and AGZ TECHNOLOGIES PRIVATE LIMITED.
    </p>
    <p><br /></p>
    <p>
      This Policy regulates Our use of Your personal information for Our own
      purposes, including how You interact with the Website and information
      submitted to Us in all interactions with Us or the Website. If You do not
      agree with the provisions of this Policy, please cease all interaction
      with the Website and leave the Website. You may instruct Us to delete any
      information collected until this point. Your continued access and use of
      the Website imply Your acceptance of the provisions of this Policy.
    </p>
    <p><br /></p>
    <p>
      AGZ TECHNOLOGIES PRIVATE LIMITED may update and revise this Policy from
      time to time. The revised Policy will be posted on the Website. You are
      instructed to periodically check the Website to stay informed about
      changes to this Policy. You hereby acknowledge and agree that it is Your
      responsibility to check this Policy periodically and make Yourself aware
      of the modifications. If You disagree to any of the changes to the Policy,
      You shall refrain from using or accessing the Website. Your continued use
      of the Website after posting of the revised Policy shall indicate Your
      acceptance and acknowledgement of the changes and You will be bound by it.
      Failure to periodically check the Website for updates to this Policy shall
      absolve Us of any liability whatsoever.
    </p>
    <p><br /></p>
    <h3>Collection of Information</h3>
    <p>
      Your use of the Website includes the collection of a variety of personal
      information from You. This is collected based on information provided by
      You to Us, from Your use of the Website and other sources. The kind of
      information collected is detailed below:
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;Personal information relating to Your account,
      involving Your full name, email address, phone number, payment
      information, information included in Your communications with us and
      identifying information provided to Us certifying Your identity.
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;Information relating to Your activity on the
      Website, including but not limited to Your location, IP address, clicks,
      scrolling, session heatmaps, timestamps, browsing times, transactions,
      load times, errors, internet service provider details, webpages visited
      prior to accessing the Website, browser and platform type, browser
      language and related details.
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;Information provided by You to Us when using
      the Website to create Your Webpage(s) [as defined in the Terms of Use],
      including but not limited to images, text, documents, certifications,
      business email addresses, business phone numbers, location details, and
      other related details.
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;Information provided by Your clients pursuant
      to their use of Your Webpage(s) such as full name, email address, phone
      number, financial information and other necessary details for the features
      provided by the Website on Your Webpage(s) to effectively function.
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;Other information You submit to Us directly or
      through third party services.
    </p>
    <p>
      Payments shall be made through third-party payment gateways as contracted
      by AGZ TECHNOLOGIES PRIVATE LIMITED from time to time. Any such data or
      information collected by Us shall be collected solely for processing and
      crediting the payments to Us, and the same shall not be retained by Us
      following the successful completion of the payment. Any information
      collected by these third-party payment gateways shall be collected as per
      their own data collection and privacy policies and You consent to making
      yourself aware of such applicable third-party policies.
    </p>
    <h3>Use of Information</h3>
    <p>We may use information collected from You for the following purposes:</p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;To control Your access to Our Website and
      services, to communicate with You, customize Your experience by providing
      information that may be useful or interesting to You;
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;To provide and operate the services offered on
      the Website, including but not limited to creating Your account,
      registering Your domain name, processing payments or responding to Your
      queries;
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;To send administrative information, changes in
      account settings, changes to Our services or any updates pertaining to the
      services;
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;To carry out Our internal business purposes,
      such as corporate transactions, audits, and data analysis; conduct
      research and analytics about Your use of Our Website and interaction with
      Us; improve Our Website and Our existing services; identify usage trends;
      assess the performance of Our advertisements; optimize Our advertising
      campaigns; and determine the effectiveness of Our communications;
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;To provide customised advertising or
      promotional material regarding products, services, third party
      affiliations and related services, offers, programs and promotions of the
      Website;
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;To ensure the security and integrity of the
      services of the Website;
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;To analyse the use of the Website, and the
      people visiting and using the Website for improving the features of the
      Website. Further, the information provided by You may also be used to help
      Us in responding to Your requests and support needs more efficiently;
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;To assist Our marketing partners by providing
      Your personal information to them for the purpose of promotional or
      marketing campaigns. AGZ TECHNOLOGIES PRIVATE LIMITED shall not be
      responsible for any harmful, fraudulent, harassing or other nuisance
      resulting from the activities carried out by such marketing partners owing
      to the sharing of personal identifiable information with such parties.
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;To assist law enforcement agencies and ensure
      compliance with applicable laws and regulations if the disclosure of Your
      information is warranted by law or is warranted by any judicial proceeding
      by a court of competent jurisdiction.
    </p>
    <p><br /></p>
    <h3>Third Party Disclosure</h3>
    <p>
      We will not disclose any of Your information to any third parties except
      with regard to the following instances:
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;Your payment information which is required and
      managed by the policies of AGZ TECHNOLOGIES PRIVATE LIMITED’s designated
      payment gateway partner(s);
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;Your personal information is required to be
      shared with AGZ TECHNOLOGIES PRIVATE LIMITED’s support services providers;
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;Your personal information may be displayed as
      part of AGZ TECHNOLOGIES PRIVATE LIMITED’s marketing and promotional
      campaigns for the services of the Website;
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;Your personal information is required to be
      shared with government agencies as required by law;
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;Your personal information is required to be
      shared with AGZ TECHNOLOGIES PRIVATE LIMITED’s analytical software tools
      for tracking use of the Website in an effort to optimise the Website.
    </p>
    <p><br /></p>
    <h3>Use of Cookies</h3>
    <p>
      We set and access cookies on Your device to track the preferences and
      trends of Your searches in order to provide You with more personalized and
      improved services. You may reset the cookies to refuse such access by Us.
      However, in such a case, You may not be able to access some parts or
      features of Our Website.
    </p>
    <p>We may provide cookies for the following actions:</p>
    <p>·&nbsp; &nbsp; &nbsp;&nbsp;Keeping You signed in</p>
    <p>·&nbsp; &nbsp; &nbsp;&nbsp;Maintaining Your preferences</p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;Tracking analytical data of Your use of Our
      Website, extending to performance, operation and effectiveness
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;Tracking data for the effectiveness of Our
      marketing campaigns
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;Security and risk analysis programs, fraud
      protection purposes, and in order to identify and prevent cyber attacks
    </p>
    <p><br /></p>
    <p>
      You can choose to accept or decline cookies. Most web browsers
      automatically accept cookies, but You can modify Your browser setting to
      decline cookies if You prefer.
    </p>
    <h3>Security and Confidentiality</h3>
    <p>
      We understand and recognize that privacy on the internet is very
      important. We take all reasonable measures to protect the confidentiality
      of the information disclosed by You and will not disclose the information
      unless as provided in this Policy. We provide creation of
      password-protected accounts, which can be accessed only by You. We advise
      You to not share Your password with any other person and always log out
      before closing the browser window. However, AGZ TECHNOLOGIES PRIVATE
      LIMITED retains limited access to Your account in the event that You
      require support with Your Webpage(s), and members of AGZ TECHNOLOGIES
      PRIVATE LIMITED are required to access Your Webpage(s) in order to rectify
      any error. In such instances, members of AGZ TECHNOLOGIES PRIVATE LIMITED
      may utilise Your account credentials for the aforementioned purpose, and
      You consent to the same.
    </p>
    <h3>Retention of Data</h3>
    <p>
      AGZ TECHNOLOGIES PRIVATE LIMITED will retain Your data for a period that
      it deems necessary for providing services to You in relation to Your
      account, or as prescribed under law, whichever is later, and should Your
      account be deleted at Your request, AGZ TECHNOLOGIES PRIVATE LIMITED will
      delete Your information, save what is necessary to comply with applicable
      law or requests from government agencies. Your information may also be
      retained for the purpose of Our legal defence in disputes raised by You or
      related to You.
    </p>
    <p>
      Users that no longer wish to associate and/or access the Website may
      intimate the same to Us. Your information and data, including personal
      information, shall only be retained for archival purposes in accordance
      with applicable laws and regulations.
    </p>
    <p><br /></p>
    <h3>Extension to Californian Residents</h3>
    <p>
      If You are a resident of California, in accordance with applicable
      Californian law, You have the right to request information from AGZ
      TECHNOLOGIES PRIVATE LIMITED relating to AGZ TECHNOLOGIES PRIVATE
      LIMITED’s sharing of Your information, including personal identifiable
      information, with marketing associates and other third parties. You may
      submit a request to AGZ TECHNOLOGIES PRIVATE LIMITED through the contact
      email provided below in the Contact clause and receive the following
      information:
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;The categories of information disclosed to
      third parties for the third parties’ marketing purposes during the
      preceding calendar year (determined according to the date of the request);
    </p>
    <p>
      ·&nbsp; &nbsp; &nbsp;&nbsp;The names and addresses of third parties that
      received such information, or if the nature of their business cannot be
      determined from the name, then instances of the products or services
      marketed.
    </p>
    <p>
      You are entitled to receive a copy of this information in a standardised
      format and the information will not be specific to You individually.
    </p>
    <p>Contact</p>
    <p>
      If you have any questions about this Privacy Policy or Your dealing with
      Our Website or any of the features, please contact Us at
      <a href="mailto:hello@getmacha.com">hello@getmacha.com</a>&nbsp;
    </p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>