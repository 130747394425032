<template>
  <div>
    <!--START: App Router View-->
    <keep-alive>
      <router-view />
    </keep-alive>
    <!--END: App Router View-->
  </div>
</template>

<script>
// Importing Components
import { useRoute, useRouter } from "vue-router";

export default {
  name: "MachaWebsite",
  setup() {
    const router = useRouter();
    const route = useRoute();

    return { router, route };
  },
  data() {
    return {};
  },
  components: {},
  async mounted() {
    this.getQueryParameters();
  },
  methods: {
    async getQueryParameters() {
      await this.router.isReady();
      this.$store.commit("updateQueryParameters", this.route.query);
    },
  },
};
</script>

<style>
</style>