<template>
  <div>
    <h1>Terms Of Service</h1>
    <p>
      These Terms of Use (“Terms”) govern the use of the
      <a href="https://www.getmacha.com">www.getmacha.com</a> (“Website”) by the
      user (“You”, “Your”, “Yourself” or “User”). Please read these Terms
      carefully before proceeding to use, access or view the Website, which is
      owned, managed and controlled by AGZ Technologies Private Limited (“AGZ
      TECHNOLOGIES PRIVATE LIMITED”, “Us”, “Our” or “We”). These Terms, along
      with any modifications and/or amendments, constitutes a valid contract
      between You and Us. These Terms, along with the Privacy Policy, applicable
      laws and regulations, shall apply to Your use and access of the Website,
      and certain provisions shall apply to You depending on their applicability
      to the situation. Certain provisions may also apply to You according to
      whether You are situated in India (“Indian User”) or whether You are
      situated outside India (“Non-Indian User”).
    </p>
    <p><br /></p>
    <p>
      You understand that failure to read, evaluate or otherwise make Yourself
      aware of the existence of the provisions of these Terms shall not
      constitute a valid excuse for failing to comply with any of the
      obligations contained herein. You warrant that it shall be Your sole
      responsibility to keep Yourself updated as to any and all modifications or
      amendments to these Terms, and AGZ TECHNOLOGIES PRIVATE LIMITED’s duty in
      this regard shall be limited to making any such modifications or
      amendments available on the Website.
    </p>
    <p><br /></p>
    <p>
      You further understand, consent and warrant that continued access and use
      of the Website shall exhibit Your understanding of these Terms and shall
      constitute valid consent to be bound by these Terms. Should You not agree
      with these Terms, or lack the required authority as prescribed by law to
      access and use the Website on behalf of another individual or legal
      entity, You are required to cease all access and use of the Website, and
      We shall not be liable for any damage caused to You by such access and use
      of the Website, and further, You shall be liable, for both compensatory
      and punitive damages, to Us for any injury caused to Us by Your
      unauthorised and/or unlicensed use of the Website.
    </p>
    <p><br /></p>
    <h3>Website Services</h3>
    <p>
      The Website provides Users with the ability to design their own webpage(s)
      using templates, images, texts, shapes, icons, artwork, graphics, widgets,
      designs, animations, interfaces, documentation and derivatives therein,
      along with underlying proprietary code to support the structure of the
      webpage(s). The webpage(s) designed by You using the resources made
      available by the Website shall hereinafter be referred to as the
      Webpage(s). You, being desirous of creating Your own Webpage(s) and
      hosting the same on Our Website, will be allowed to use the same in
      accordance with the provisions of these Terms. AGZ TECHNOLOGIES PRIVATE
      LIMITED and the Website are merely intermediaries providing You with the
      ability to design and host Webpage(s) for Your services.
    </p>
    <p><br /></p>
    <h3>Registration</h3>
    <p>
      Your access and use of the Website is determined by Your registration on
      the Website by creating a user account and subscribing to the Website for
      a prescribed fee. You hereby agree to provide details relating to Your
      full name, email address, phone number and financial details (including
      bank account number, IFSC code, payment account details, etc.). You
      warrant that the information provided by You, or on Your behalf by Your
      representative (which shall mean persons to whom You have provided Your
      account credentials), shall be true and accurate, and AGZ TECHNOLOGIES
      PRIVATE LIMITED shall not be liable for any loss or injury arising out of
      inaccurate information.
    </p>
    <p><br /></p>
    <p>
      You warrant that You shall take all necessary measures to protect the
      confidentiality of Your account credentials. AGZ TECHNOLOGIES PRIVATE
      LIMITED shall bear no liability for any loss, injury or damage arising out
      of any unauthorised access resulting from Your failure to maintain the
      confidentiality of the same. If You believe that Your account credentials
      have been compromised, You must first opt to change Your password by
      selecting the option available on the Website. Should You have reason to
      believe that this would not be an effective solution, You are required to
      immediately notify Us.
    </p>
    <p><br /></p>
    <p>
      You warrant that You have attained the age of majority in Your
      jurisdiction. If You have not attained the age of majority, You are
      required to cease continued use and/or access to the Website, and any
      further use or access of the Website shall absolve AGZ TECHNOLOGIES
      PRIVATE LIMITED and the Website of any liability arising out of any loss,
      injury or damage resulting from such use.
    </p>
    <p><br /></p>
    <h3>Access to Website</h3>
    <p>
      AGZ TECHNOLOGIES PRIVATE LIMITED warrants that it shall employ its best
      efforts to ensure the continuous operation of the Website and Webpage(s)
      to You. However, access to the Website and Webpage(s) may be temporarily
      suspended owing to extraneous causes, downtimes, site maintenances, or for
      the introduction of additional features.
    </p>
    <p><br /></p>
    <h3>Collection of Data</h3>
    <p>
      The Website requires You to provide certain personal and financial
      information in order to operate in a smooth and efficient manner, as well
      as for the purposes of maintaining records, enhancing promotion
      strategies, enabling targeted advertisements, complying with statutory
      obligations and/or assisting with the integration of third-party
      applications, links or software that AGZ TECHNOLOGIES PRIVATE LIMITED
      deems intrinsic to the functioning of the Website and/or Webpage(s). AGZ
      TECHNOLOGIES PRIVATE LIMITED may collect any information resulting from
      Your access and/or use of the Website and/or Webpage(s) for the purpose of
      evaluating efficiency, comfort or otherwise of the Website and/or
      Webpage(s), or for any other reasonable purpose. You understand and agree
      that the collection, storage, archiving, use or analysis of such
      information shall be governed by the Privacy Policy. The Privacy Policy
      can be accessed here.
    </p>
    <p><br /></p>
    <h3>User Obligations</h3>
    <p>You hereby warrant that You shall:</p>
    <p>
      Use the Website and/or Webpage(s) only through authorised channels using
      Your account credentials.
    </p>
    <p>
      Use only the content made available by the Website except where You are
      allowed to input Your content into Your Webpage(s).
    </p>
    <p>
      Use only Your original content free from any intellectual property claims
      and/or proceedings, and otherwise ensure that the content made available
      on Your Webpage(s) is free from any encumbrances.
    </p>
    <p>
      Act in a manner that is in consonance with all applicable laws and
      regulations.
    </p>
    <p>
      Ensure that the performance of Your services on Your Webpage(s) meets the
      highest standard of performance so as to not reflect poorly on AGZ
      TECHNOLOGIES PRIVATE LIMITED or the Website.
    </p>
    <p>
      Report any glitches, disruptions in service, poor performance or any other
      aspect of the services offered on/by the Website and/or Webpage(s) before
      taking further action so as to allow AGZ TECHNOLOGIES PRIVATE LIMITED to
      amicably resolve any issues.
    </p>
    <p>
      Not remove, duplicate, or copy any information and content on the Website
      other than on the Webpage(s) as permitted.
    </p>
    <p>
      Not use the Website or any information contained on it for any unlawful,
      fraudulent, harmful or illegal purposes or in contravention to applicable
      data protection regulations.
    </p>
    <p>
      Not directly or indirectly alter, remove or attempt to alter or remove any
      trademark, copyright or other proprietary or legal notices appearing on
      the Website or on any contents appearing on the Website.
    </p>
    <p>
      Not deliberately impersonate another person, whether real or fictional or
      otherwise misrepresent Your affiliation with a person or entity.
    </p>
    <p>
      Not circumvent any security or authentication measures employed on the
      Website. You shall not trace or seek to trace any information about any
      other user of the Website.
    </p>
    <p>
      Not share proprietary information as to the technical or administrative
      mechanisms employed by AGZ TECHNOLOGIES PRIVATE LIMITED in the smooth
      functioning of the Website and/or Webpage(s).
    </p>
    <p>Third Party Services</p>
    <p>
      AGZ TECHNOLOGIES PRIVATE LIMITED or the Website may utilise third-party
      services for the smooth and efficient functioning of the Website and/or
      Webpage(s), or to provide additional opt-in features to enhance Your
      experience using the Website and/or Webpage(s). Your continued use or
      opt-in for these services implies You agree and consent to complying with
      their terms of use and privacy policy, and the Website shall not be liable
      for any loss, injury or damage resulting from Your interaction with these
      services. The Website only acts as an intermediary between You and these
      third-party services, and does not endorse them in any way whatsoever.
    </p>
    <p>
      The Website may contain links to other apps and websites. You understand
      that these linked sites are not under Our control, and We are not
      responsible for the contents of such linked sites. These links have been
      provided to You only as a convenience and the inclusion of any such link
      does not imply endorsement by Us of such parties or any association with
      such sites or its operators.
    </p>
    <p><br /></p>
    <h3>Intellectual Property</h3>
    <p>
      All right, title and interest including all intellectual property rights
      in the Website and its contents including without limitation, code, texts,
      logos, designs, images, formats, documents, video, links, graphics and all
      other materials published on the Website are the sole and exclusive
      property of AGZ TECHNOLOGIES PRIVATE LIMITED. AGZ TECHNOLOGIES PRIVATE
      LIMITED provides to you a personal, non-exclusive, non-assignable,
      non-transferable, worldwide, limited license to use the content provided
      on the Website for the sole purpose of designing and use of Your
      Webpage(s).
    </p>

    <p>
      You shall not directly or indirectly, copy, reproduce, modify, edit,
      amend, alter, vary, enhance, improve, upgrade, create derivative works,
      translate, adapt, abridge, delete, display, perform, publish, distribute,
      circulate, communicate to the public, disseminate, transmit, sell, rent,
      lease, lend, assign, license, sub-license, market, promote, circulate,
      exploit, digitally alter or manipulate the Website or any parts thereof in
      any manner, without the prior written permission of AGZ TECHNOLOGIES
      PRIVATE LIMITED, other than as provided in the limited license to the
      extent of Your Webpage(s).
    </p>

    <p>
      You shall remain the sole owner of any intellectual property individually
      developed by You outside of the resources provided by the Website,
      including any texts, logos, designs, images, formats, documents, video,
      links, graphics and any related materials. However, You grant AGZ
      TECHNOLOGIES PRIVATE LIMITED the permission to access, upload, copy and
      make backups of Your content so as to perform any technical maintenance or
      repairs, or to perform any other required services. You also provide a
      non-exclusive, royalty free, transferable, sub-licensable, worldwide
      license to AGZ TECHNOLOGIES PRIVATE LIMITED to use Your Webpage(s) or the
      content contained therein, as well as any derivative works for the
      purposes of promotion and/or marketing of the services of AGZ TECHNOLOGIES
      PRIVATE LIMITED and the Website.
    </p>
    <p><br /></p>
    <h3>Payment</h3>
    <p>
      Payments can be availed by the Users through the designated third-party
      gateways. Users can create their own Webpage(s) through the Website, and
      utilise the services of the payment gateway designated by AGZ TECHNOLOGIES
      PRIVATE LIMITED to receive payments. Users opting to use the Website for
      receiving payments from clients agree to use the Website’s designated
      third-party payment gateway. Users may link their personal payment
      accounts of the designated third-party payment gateway to receive payments
      directly from the clients of the respective Webpage(s).
    </p>

    <p>
      The User also agrees to the collection of financial information of both
      the User as well as the User’s clients by the payment gateway per its
      terms and conditions. The Website is not an intermediary for the
      designated payment gateway and does not store any financial information
      apart from necessary financial information to transfer client payments to
      You.
    </p>

    <p>
      AGZ TECHNOLOGIES PRIVATE LIMITED has the right to amend its pricing policy
      from time to time. AGZ TECHNOLOGIES PRIVATE LIMITED shall give you
      reasonable notice as to any changes in its pricing policy when the same
      arises. You herein agree and consent to the levy of a processing fee by
      AGZ TECHNOLOGIES PRIVATE LIMITED on any products or services purchased by
      Your client through the Webpage(s). You further agree to any processing or
      other charges levied by the payment gateway for processing the purchase
      transaction, over and above the processing fee levied by AGZ TECHNOLOGIES
      PRIVATE LIMITED. AGZ TECHNOLOGIES PRIVATE LIMITED shall not be liable for
      any amounts due from You to the payment gateway. Further, in case of
      refunds from You to Your client, AGZ TECHNOLOGIES PRIVATE LIMITED shall
      not be liable to refund the processing fee levied by it, or the processing
      fee levied by the payment gateway, to Your client, and You shall bear the
      sole responsibility for initiating refunds and compensating Your client.
    </p>

    <p>
      AGZ TECHNOLOGIES PRIVATE LIMITED shall endeavour to provide a smooth
      payment process as is reasonably foreseeable. However, in case of
      glitches, unstable network connections, server latency, or any other
      factor that may impede a purchase transaction, before, during or after the
      processing of a purchase transaction, AGZ TECHNOLOGIES PRIVATE LIMITED
      shall not be liable for any loss, damage, duplicate charge or any other
      additional amount resulting from such errors.
    </p>
    <p><br /></p>
    <h3>Representations and Warranties</h3>
    <p>
      You agree that Your use of the Website shall be at Your sole risk. To the
      fullest extent permitted by law, We disclaim all warranties, express or
      implied, in connection with the services and Your use thereof.
    </p>

    <p>
      We represent and warrant that the products, services and information on
      the Website are provided “as is” and AGZ TECHNOLOGIES PRIVATE LIMITED and
      its officers, directors, employees, and agents make no, and hereby
      disclaim any express or implied, warranties, including, but not limited
      to, the implied warranties of performance, accuracy, non-infringement and
      fitness for a particular purpose. In no event shall AGZ TECHNOLOGIES
      PRIVATE LIMITED be liable to You or Your clients, for any direct,
      indirect, incidental, special, exemplary, or consequential damages
      (including, but not limited to, procurement of substitute goods or
      services; loss of use, data, or profits; or business interruption) however
      caused and on any theory of liability, whether in contract, strict
      liability, or tort (including negligence or otherwise) arising in any way
      out of the use of the Website or Your Webpage(s).
    </p>

    <p>
      We make no representations or guarantees and disclaim that the Website
      will be free from loss, destruction, damage, hacking or security
      intrusion, corruption, attack, any interruption or cessation of
      transmission to or from Our services, any bugs, viruses, Trojan horses, or
      the like which may be transmitted to or through the Website by any third
      party, and/or any errors or omissions in any information or for any loss
      or damage of any kind incurred as a result of the use of any information
      posted, emailed, transmitted, or otherwise made available via the Website.
    </p>

    <p>
      We make no representations, warranties or guarantees as to the quantity or
      quality of customer interaction through the Website and/or Your
      Webpage(s). You hereby agree and consent that AGZ TECHNOLOGIES PRIVATE
      LIMITED, or the Website, shall not be liable for any negative interactions
      with Your clients or potential clients, that may contain lewd,
      pornographic, paedophilic, inflammatory, seditious, offensive
      (religiously, socially or politically), harmful, illicit, or otherwise
      derogatory comments. Knowing that You may choose to share your contact
      information on Your webpage, and in doing so, You hereby undertake, agree
      and consent to being exposed to client interactions and that the Website
      does not make any representations as to the quality of these interactions.
      You hereby agree and consent that the Website institutes no screening
      policy for Your potential clients, and it is solely Your duty to interact
      and screen Your potential clients. The Website shall not be liable in any
      manner whatsoever, for any loss, damage or injury resulting from Your
      interaction with Your clients/potential clients, either legal or monetary.
    </p>
    <p><br /></p>
    <h3>Refund and Cancellation Policy</h3>
    <p>
      AGZ TECHNOLOGIES PRIVATE LIMITED reserves the right to remove Your
      Webpage(s) from the Website pursuant to any legitimate claim received as
      to the content made available on Your Webpage(s). AGZ TECHNOLOGIES PRIVATE
      LIMITED shall reach a decision to remove Your Webpage(s) upon verifying
      the validity of such claim, and reserves the right to suspend Your
      Webpage(s) pending such decision. Any decision reached by AGZ TECHNOLOGIES
      PRIVATE LIMITED shall be final and binding, and shall not be subject to
      review.
    </p>

    <p>
      Claims may be entertained on the grounds that Your Webpage(s) contains
      content that is:
    </p>

    <ul>
      <li>
        harmful, offensive, insensitive, inaccurate, falsely misrepresents, or
        otherwise damages the reputation of the Website;
      </li>
      <li>contrary to the applicable laws of India;</li>
      <li>
        deceives clients about the identity or activities of the User or
        misleads the clients about the origin of the materials posted on Your
        Webpage(s);
      </li>
      <li>
        consists of any material that infringes the intellectual property, right
        of privacy or right of publicity of any other person, or otherwise
        purports to be under license from the owner when no license has been
        issued;
      </li>
      <li>harasses or advocates the harassment of another person;</li>
      <li>
        promotes illegal activity or conduct that is abusive, threatening,
        obscene, defamatory, or libellous;
      </li>
      <li>
        offensive to an extent that would cause AGZ TECHNOLOGIES PRIVATE LIMITED
        to lose the support of its suppliers and/or stakeholders.
      </li>
      <li>
        Services provided by AGZ TECHNOLOGIES PRIVATE LIMITED are
        non-refundable, and You agree and consent to the same. AGZ TECHNOLOGIES
        PRIVATE LIMITED provides that it will take all necessary steps to
        rectify any issues or errors that arise during Your use of the Website,
        upon notification of the same by You. If AGZ TECHNOLOGIES PRIVATE
        LIMITED comes to the conclusion that the issue or error cannot be
        resolved and has arisen by AGZ TECHNOLOGIES PRIVATE LIMITED’s sole
        fault, then AGZ TECHNOLOGIES PRIVATE LIMITED may choose to refund the
        amount paid, if any, for the service purchased. All decisions in this
        regard by AGZ TECHNOLOGIES PRIVATE LIMITED shall be final and binding,
        and shall not be subject to review.
      </li>
    </ul>
    <p><br /></p>
    <h3>Disclaimer of Liability</h3>
    <p>
      To the maximum extent permitted by applicable law, in no event shall We be
      liable for any direct, indirect, incidental, punitive, special or
      consequential damages, for loss of profits or confidential or other
      information, for business interruption, for personal injury, for loss of
      privacy, for failure to meet any duty including of good faith or of
      reasonable care, for negligence, and for any other pecuniary or other loss
      whatsoever arising out of or in any way related to the use or inability to
      use the Website and/or Your Webpage(s) and any loss or damage arising out
      of or in relation to Acts of God or act of third party that is beyond Our
      control.
    </p>

    <p>
      AGZ TECHNOLOGIES PRIVATE LIMITED will, and You agree and consent to the
      same, not be liable for any loss, damage or injury caused to any client of
      the User’s Webpage(s) resulting from the products or services made
      available by the User to the client. You agree to bear sole responsibility
      for any loss, damage or injury caused to any person by Your use of the
      services of the Website, including any content uploaded by You on Your
      webpage.
    </p>
    <p><br /></p>
    <h3>Modifications</h3>
    <p>
      We reserve the right to update, revise, supplement, and otherwise modify
      these Terms and to impose new or additional rules, policies, terms, or
      conditions on Your use of the Website and/or Webpage(s), at any time,
      without notice to You. Any material updates, revisions, supplements,
      modifications, and additional rules, policies, terms, and conditions will
      be posted on the Website and will be effective immediately after such
      posting and thereafter automatically incorporated into these Terms. You
      hereby agree to be bound by all such additional terms and Your continuous
      use of the website shall be deemed to be the acceptance of such terms.
    </p>
    <p><br /></p>
    <h3>Indemnity</h3>
    <p>
      You hereby agree to indemnify and hold harmless AGZ TECHNOLOGIES PRIVATE
      LIMITED and its employees, directors, affiliates, associates and agents
      from and against any and all claims, damages, obligations, losses,
      liabilities, costs or debts and expenses including reasonable attorney’s
      fees arising directly or indirectly from Your breach of these Terms.
    </p>
    <p><br /></p>
    <h3>Governing Law and Jurisdiction</h3>
    <p>
      This Agreement shall be governed, interpreted and construed in accordance
      with the laws in force in India and is subject to the exclusive
      jurisdiction of the courts at Bengaluru, Karnataka, India.
    </p>

    <p>
      Any and all disputes under this Agreement shall be referred to a sole
      Arbitrator, who shall be mutually appointed by the parties. However, if
      the parties are unable to mutually appoint an arbitrator, such arbitrator
      shall be appointed by the competent court in Bengaluru having appropriate
      jurisdiction as per the Arbitration and Conciliation Act, 1996 (as amended
      from time to time) (“Arbitration Act”). The venue of the arbitration shall
      be Bengaluru. The proceedings of the arbitration shall be in English. The
      parties hereby agree that the arbitrator’s award shall be binding on the
      parties. All costs of such arbitration shall be borne equally between the
      parties.
    </p>
    <p><br /></p>
    <h3>Severability</h3>
    <p>
      If any provision hereof is held invalid or unenforceable by a court of
      competent jurisdiction, such invalidity shall not affect the validity or
      operation of any other provision and such invalid provision shall be
      deemed to be severed from these Terms.
    </p>

    <p>
      You acknowledge that You have read and understood this Agreement and You,
      out of Your free will, unconditionally accept to be bound by the same.
    </p>
    <p><br /></p>
    <h3>Relationship</h3>
    <p>
      You hereby agree that the relationship between You and AGZ TECHNOLOGIES
      PRIVATE LIMITED is one where AGZ TECHNOLOGIES PRIVATE LIMITED is acting as
      an intermediary to enable You to establish Your Webpage(s). AGZ
      TECHNOLOGIES PRIVATE LIMITED and the Website is merely an intermediary,
      and nothing shall be construed to mean that You have been endorsed or
      backed by Us, including the use of skpr.me (or any other web extension
      provided by us) in Your Webpage(s). These Terms and Your use of the
      services of the Website shall not be construed to imply the existence of
      any partnership, joint venture, employer-employee, agency, or franchise
      relationship between the Website (or AGZ TECHNOLOGIES PRIVATE LIMITED) and
      You.
    </p>
    <p><br /></p>
    <h3>Electronic Communications</h3>
    <p>
      All communications made between Us and You shall be made through displays
      on the Website, and it shall be Your duty to avail yourself to such
      communications made through the Website. You agree that all postings,
      notices, disclosures, or other communications that We provide to You
      electronically shall satisfy any legal requirements that such
      communications be in writing.
    </p>

    <p>
      Any complaints or concerns with respect to the use of the Website may be
      addressed to the Grievance Officer, Abbas M, who can be contacted via
      email at <a href="mailto:hello@getmacha.com">hello@getmacha.com</a> &nbsp;
    </p>
  </div>
</template>

<script>
export default {
  name: "TermsOfUse",
};
</script>