<template>
  <div>
    <!--START: Header and Gradient-->
    <Header currentSection="" :theme="headerTheme"></Header>
    <!--END: Header and Gradient-->

    <div id="policy-wrapper" class="white-background scroll-section">
      <!--START: App Router View-->
      <keep-alive>
        <router-view class="policy-wrapper" />
      </keep-alive>
      <!--END: App Router View-->

      <!--START: Customer Story-->
      <CustomerStory></CustomerStory>
      <!--END: Customer Story-->
    </div>

    <div id="statistics" class="white-background scroll-section">
      <!--START: Statistics-->
      <SupportStatistics></SupportStatistics>
      <!--END: Statistics-->

      <div class="gradient-circle"></div>
    </div>

    <!--START: Header and Gradient-->
    <Footer></Footer>
    <!--END: Header and Gradient-->
  </div>
</template>
  
<script>
//Import components
import Header from "@/components/Header";
import Footer from "@/components/Footer";

import SupportStatistics from "@/components/Home/SupportStatistics";
import CustomerStory from "@/components/Home/CustomerStory";

export default {
  name: "MachaPolicies",
  data() {
    return {
      headerTheme: "light",
    };
  },
  components: {
    Header,
    Footer,
    SupportStatistics,
    CustomerStory,
  },
  async created() {
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    // Header menu selection
    onScroll() {
      const scrollY = window.scrollY + 75;
      const sections = document.querySelectorAll(".scroll-section");

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];

        if (
          section.offsetTop <= scrollY &&
          section.offsetTop + section.offsetHeight >= scrollY
        ) {
          // Header theme styles
          switch (section.id) {
            case "policy-wrapper":
              this.headerTheme = "light";
              break;
            default:
              this.headerTheme = "dark";
              break;
          }
        }
      }
    },
  },
};
</script>
  
<style scoped lang="scss">
.white-background {
  position: relative;
  background-color: $whiteColor;
}

.gradient-circle {
  position: absolute;
  background-color: #ffe7b5;
  top: -35%;
  right: -45%;
  border-radius: 50%;
  height: 55%;
  width: 85%;
  filter: blur(100px);
  transform: translate3d(0, 0, 0);

  opacity: 0.5;
}

:deep(.policy-wrapper) {
  padding: 8rem 15% 5rem;

  h1 {
    position: relative;
    color: $whiteColor;
    -webkit-text-fill-color: transparent;
    background-image: radial-gradient(circle at bottom left, #575757, #1d1d1d);
    -webkit-background-clip: text;
    background-clip: text;
    font-weight: $lightFontWeight;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.05;
    margin-bottom: 2rem;
  }

  p {
    color: $darkBlackColor;
    margin-bottom: 1rem;
    font-weight: 500;
    line-height: 1.8;
  }

  li {
    position: relative;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    text-transform: capitalize;
    font-weight: 500;
    color: $darkBlackColor;
    line-height: 1.8;
    list-style-type: disc;
  }

  li::before {
    list-style: decimal;
  }

  ol.ol1 {
    list-style-type: decimal;
  }

  a {
    display: inline-block;
    font-size: 0.85rem;
    text-decoration: underline;
  }
}

@media screen and (max-width: 800px) {
  .policy-wrapper {
    padding: 7rem 1.5rem 5rem;
  }
}
</style>